const { imageUrlBuilder, toHTML } = require('../../utils/sanity/index.js');

module.exports = ({ $content, ...data }) => {
  const item = ({
    firstName,
    lastName,
    honorificPrefix,
    honorificSuffix,
    roles,
    content
  }) => `<div>
    <header>
      <h1>
      ${[
        honorificPrefix,
        firstName,
        lastName,
        honorificSuffix ? `<span>${honorificSuffix}</span>` : ``
      ]
        .filter((x) => x)
        .join(` `)}
      </h1>
      ${!!roles?.length ? `<h2>${roles.join(', ')}</h2>` : ``}
    </header>
    ${toHTML(content, data)}
  </div>`;

  return `<section class="block-keyPeople">${$content.teams
    .map(({ title, members, description }) => {
      if (title == 'Executive Management Team')
        [director, ...members] = members;

      const html = `<section data-team="${title}">
        <header class="section-header">
          <h2 class="heading-lg">${title}</h2>
        </header>

        ${description ? toHTML(description, data) : ``}

        <ul>${members
          ?.map(
            ({ profileImage, ...member }) =>
              `<li class="card">
              <article>
            <img src="${imageUrlBuilder(profileImage).width(427).height(427)}">
            ${item(member)}
            </article>
          </li>`
          )
          .join('')}
        </ul>
      </section>`;

      return title == 'Executive Management Team'
        ? html.replace(
            `<ul`,
            `<article>
        <img src="${imageUrlBuilder(director.profileImage)
          .width(885)
          .height(758)}">
        ${item(director)}
      </article>
      <ul`
          )
        : html;
    })
    .join('')}`;
};
