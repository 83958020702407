import '@virtualstate/navigation/esnext/polyfill-rollup.js';

export const init = (Alpine) => {
  Alpine.data('modal', component);
};

export const component = function () {
  const initialTitle = document.title;

  return {
    closing: false,
    open: false,
    isAjax: false,
    loading: false,
    content: null,
    interceptPaths: new Set(),
    addAjaxModalPath(qualifier) {
      try {
        if (typeof qualifier === 'string') {
          qualifier = new RegExp(qualifier)
        }

        if (qualifier instanceof RegExp) {
          let pattern = qualifier
          qualifier = (url) => new URL(url).pathname.match(pattern)
        }

        if (!qualifier || typeof qualifier !== 'function') {
          throw new Error('Modal must be a function or regex pattern/string')
        }

        navigation.addEventListener('navigate', handleNavigate);
        document.addEventListener('mouseenter', handlePrefetch, { capture: true });
      } catch (error) {
        console.error(error)
      }

      async function handleNavigate(event) {
        const { destination } = event;
        const destinationState = destination.getState() || {};

        if (!destinationState?.modal) {
          const destinationUrl = new URL(destination.url);

          if (qualifier(destinationUrl, { destination })) {
            const prevUrl = new URL(navigation.currentEntry.url)

            let prevEntry = navigation.currentEntry,
              prevState = prevEntry.getState();

            let backdrop = (prevState?.modal && prevState?.backdrop) || (prevEntry.url !== destination.url && prevEntry.key)

            prevUrl.searchParams.forEach((v, k) => {
              destinationUrl.searchParams.delete(k)
              destinationUrl.searchParams.append(k, v)
            })

            event.intercept({
              async handler() {
                navigation.navigate(destinationUrl, {
                  history: 'replace',
                  state: {
                    ...destinationState,
                    backdrop,
                    modal: true
                  }
                })
              }
            });
          }
        }
      }

      function handlePrefetch({ target }) {
        if (target.tagName === 'A' && !target.prefetch) {
          const targetUrl = new URL(target.href);

          if (qualifier(targetUrl, { isPrefetch: true })) {
            targetUrl.searchParams.set('modal', true);

            if (
              !document.querySelector(
                `link[rel="prefetch"][href="${targetUrl}"]`
              )
            ) {
              new Promise((resolve, reject) => {
                const ms = 500;

                console.group(`Should prefetch? ${targetUrl}`);
                console.log(`Waiting ${ms}ms`);

                target.addEventListener(
                  'mouseleave',
                  (event) =>
                    reject({
                      message: 'Mouse exited target',
                      detail: event
                    }),
                  { once: true }
                );

                setTimeout(resolve, ms);
              })
                .then(() => console.log('Starting prefetch'))
                .then(() => {
                  const prefetch = document.createElement('link'),
                    loaded = new Promise(
                      (resolve) => (prefetch.onload = resolve)
                    );

                  Array.from(
                    document.querySelectorAll(
                      `a[href="${target.getAttribute('href')}"]`
                    )
                  ).forEach((link) => (link.prefetch = prefetch));

                  prefetch.rel = 'prefetch';
                  prefetch.as = 'document';
                  prefetch.href = targetUrl;

                  document.head.append(prefetch);

                  return loaded;
                })
                .then(() => console.log('Finished prefetch'))
                .catch((e) => console.log(e.message, e))
                .finally(() => console.groupEnd(targetUrl));
            }
          }
        }
      }
    },
    init() {
      const _self = this;

      document.addEventListener('modal:register', ({ detail: qualifier }) => {
        this.addAjaxModalPath(qualifier)
      });

      Alpine.effect(() => {
        if (!this.closing && this.$el.open) {
          this.$el.close();
        }
      });

      Alpine.effect(() => {
        if (this.open && !this.$el.open) {
          this.$el.showModal();
        } else if (!this.open && this.$el.open) {
          this.closing = true;
        }
      });

      this.$el.addEventListener('animationend', () => {
        if (this.closing) this.closing = false;
      });

      this.$el.addEventListener('close', () => {
        this.closing = false;
        this.open = false;
      });

      function openModal(content, { isAjax = false }) {
        _self.open = true;
        _self.loading = true;
        _self.isAjax = isAjax;

        return Promise.resolve(content)
          .then((content) => {
            if (content) {
              _self.content = content;
            }
          })
          .catch((e) => {
            console.error(e);
            _self.content = 'An error occurred. Please try again.';
          })
          .finally(() => {
            _self.loading = false;
          });
      }

      function openAjaxModal({ destination, signal }) {
        return openModal(
          new Promise((resolve) => {
            let state = destination.getState() || {};

            if (state?.content) return resolve(state.content);

            let destinationUrl = new URL(destination.url);

            destinationUrl.searchParams.set('modal', true);

            resolve(
              fetch(destinationUrl, {
                signal,
                cache: 'default',
                mode: 'same-origin',
                priority: 'high',
                headers: {
                  'Content-Type': 'text/html'
                }
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error(response.statusText)
                  }

                  return response.text()
                })
                .then((response) =>
                  new DOMParser().parseFromString(response, 'text/html')
                )
                .then((modalDocument) =>
                  navigation.updateCurrentEntry({
                    state: {
                      ...navigation.currentEntry.getState(),
                      modal: true,
                      content: modalDocument.body.innerHTML,
                      title: modalDocument.title
                    }
                  })
                )
            );
          }),
          { isAjax: true, signal }
        );
      }

      navigation.addEventListener('currententrychange', async (event) => {
        const state = event.target.currentEntry.getState();

        if (state?.modal) {
          let { content, title = initialTitle } = state;

          document.title = title;
          _self.content = content;
          _self.loading = false;
        }
      });

      navigation.addEventListener('navigate', async (event) => {
        const { destination } = event

        if (destination.getState()?.modal) {
          event.intercept({
            async handler() {
              await openAjaxModal(event);
            },
            scroll: 'manual'
          });
        } else if (destination.key === navigation.currentEntry.getState()?.backdrop) {
          _self.open = false;
        }
      });

      if (navigation.currentEntry.getState()?.modal && navigation.currentEntry.getState()?.content) {
        openAjaxModal({ destination: navigation.currentEntry })
      }
    },
    wrapper: {
      'x-ref': 'modal',
      ':class': `{
        modal: true,
        closing: closing,
        loading: loading
      }`,
      '@click.self': 'open = false',
      '@close'() {
        if (this.isAjax && navigation.currentEntry.getState()?.backdrop) {
          navigation.traverseTo(navigation.currentEntry.getState().backdrop);
        }

        this.content = null;
      }
    },
    closeButton: {
      type: 'button',
      '@click': 'open = false',
      ':class': '"close-button"',
      'x-html': '`<span class="sr-only">Close</span>`'
    },
    loader: {
      'x-transition.opacity': '',
      'x-show': 'loading',
      'x-html': '`Loading...`',
      ':class': '"loader"'
    },
    contentWrapper: {
      'x-show': 'content && !loading',
      'x-html': 'content || ``'
    }
  };
};

export default init;
