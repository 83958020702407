const { eventUrl } = require('../../$content/events');
const { toHTML } = require('../../../src/utils/sanity/index.js');
const { imageHtml } = require('../_partials/image.js');
const Icon = require('../../utils/icon.js');

module.exports = function ({
  title,
  text,
  layout = 'mosaic',
  items,
  blockHelpers,
  ...data
}) {
  title = title != data.item?.title && title;

  const linkHtml = ({ url, reference, hash, text }) => {
    if (reference) {
      if (reference?.url) {
        url = reference.url;
      } else {
        if (reference?._type === 'event') {
          url = eventUrl(reference) + (hash ? `#${hash}` : ``);
        } else if (reference?._ref || reference?._id) {
          let page = data.links[reference?._ref || reference?._id];
          if (page) url = page + (hash ? `#${hash}` : ``);
        }
      }
    }

    return `<a href="${url}"${layout === 'mosaic' ? ` class="button"` : ``}>
      ${text} ${Icon(layout === 'mosaic' ? 'chevron' : 'arrow-right')}
    </a>`;
  };

  if (!items) return;

  if (layout === 'columns') {
    avgWord =
      items
        ?.flatMap(({ text }) =>
          text?.flatMap(({ children }) =>
            children?.flatMap(({ text }) => text?.split(' ').length)
          )
        )
        .reduce((acc, n) => acc + n, 0) / items.length;

    if (avgWord > 75) var span = 12 / (avgWord > 110 ? 2 : 3);
  }

  return `<section class="block-flexibleContent block-flexibleContent--${layout}${
    layout == 'mosaic' ? ' mosaic' : ``
  }${items.length === 1 ? ` single` : ``}${items.length >= 4 ? ` max` : ``}"
  ${span ? ` style="--g-col-span: ${span}"` : ``} ${blockHelpers.id(data)}>
    <header>
      ${title ? `<h1>${title}</h1>` : ``}
      ${text ? toHTML(text, data) : ``}
    </header>

    <div>
      ${items
        ?.map(
          ({ title, text, callToActions, videos, image, ...item }) =>
            `<article class="${blockHelpers.oddOrEven(
              'imageBlock'
            )}" ${blockHelpers.id({ ...data, ...item })}>

          ${
            image
              ? imageHtml({
                  ...image,
                  width: layout == 'mosaic' ? 885 : 758,
                  height: 758
                })
              : ``
          }
    
          <div>
            <div>
              ${title ? `<h1>${title}</h1>` : ``}

              <div>${text ? toHTML(text, data) : ``}</div>

              ${callToActions ? callToActions.map(linkHtml).join('') : ``}
            </div>
          </div>

          ${
            videos
              ? `<aside x-cloak>${videos
                  ?.map(
                    (url) => `<span x-data="youtubeVideo('${url}')"></span>`
                  )
                  .join('')}</aside>`
              : ``
          }
        </article>`
        )
        .join('')}
    </div>
  </section>`;
};
