const { grid } = require('../_partials/grid.js');
const { imageHtml } = require('../_partials/image');
const Icon = require('../../utils/icon.js');

const parent = `85aaa289-6c99-4999-9b71-298b815d069e`;

module.exports = function ({ collections, _key, title, blockHelpers }) {
  const { eleventyNavigation } = this;
  const { childrensResource } = collections;

  const resources = childrensResource
    .filter(({ data }) => data.item.type == _key)
    .slice(0, 4);

  return `<section ${blockHelpers.id({ _key })}>
  ${grid({
    title,
    link: {
      url: eleventyNavigation(collections.all, parent).find(
        ({ key }) => key == `${parent}:${_key}`
      ).url,
      text: `See all ${title.split(' ').pop()}`
    },
    items: resources.map(({ data }) => card(data.item))
  })}
</section>`;
};

const card = ({ title, image, url, file, relatedArtists }) => {
  return `<li class="card">

  ${
    image
      ? `<a href="${url}" target="_blank" tabindex="-1">
    ${imageHtml({ width: 885, height: 758, ...image })}
  </a>`
      : ``
  }

  <h1 class="heading-sm"><a href="${url}" target="_blank">${title}</a></h1>

  <div class="accent card__details">
    ${relatedArtists?.length ? `<h2>${relatedArtists.join()}</h2>` : ``}
    
    ${button(file || { url })}
  </div>
</li>`;
};

const button = ({ url, extension }) => {
  const contents = [];

  if (extension) {
    contents.push(Icon(extension), `Download`, Icon('download'));
  } else {
    contents.push(`Play`, Icon('play', 'style="width:.75em"'));
  }

  return `<a href="${url}" target="_blank" tabindex="-1" class="button button-sm">
    ${contents.filter(Boolean).join(' ')}
  </a>`;
};
