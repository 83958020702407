import { card } from '../../_includes/_partials/card';

const arrowRight = new URL(`/assets/svgs/arrow-right.svg`, import.meta.url);

export const init = (Alpine) => {
  Alpine.data('productShowcase', component);
};

export const component = ({ type = 'products', ...rest }) => ({
  ...rest,
  type,
  products: [],
  url: `https://qagoma.store/`,
  init() {
    Alpine.effect(async () => {
      if (this.type == 'tag' && this.tag) {
        this.products = await request('products', {
          query: `tag:'${this.tag}'`,
          first: 5
        });
      } else if (this.type == 'collection' && this.collection) {
        this.products = await request('productsFromCollection', {
          id: this.collection,
          first: 5
        });
      }
    });
  },
  card() {
    return {
      ['x-html']() {
        const { featuredImage, onlineStoreUrl, title, price } = this.product;

        return card({
          ...this.item,
          url: onlineStoreUrl,
          title,
          image: { url: featuredImage.url },
          wrap: false,
          details: {
            Price: new Intl.NumberFormat('en-AU', {
              style: 'currency',
              currency: 'AUD',
              currencyDisplay: undefined
            }).format(price)
          },
          icons: {
            'arrow-right': arrowRight
          }
        });
      }
    };
  }
});

const request = (endpoint, params) =>
  fetch(`/_/store/${endpoint}?${new URLSearchParams(params)}`)
    .then((response) => response.json())
    .then((response) => response || []);

export default init;
