import intersect from '@alpinejs/intersect';

export const init = (Alpine) => {
  Alpine.plugin(intersect);
  Alpine.data('header', component);
  Alpine.data('headerMenu', headerMenu);
};

export const component = () => ({
  hasOpen: false,
  show: true,
  currentScroll: window.pageYOffset,
  scrollDir: 0,
  offset: '0px',
  pastThreshold: false,
  init() {
    this.$watch('currentScroll', (currentScroll, prevScroll) => {
      let dir = currentScroll > prevScroll ? 1 : -1;
      if (dir !== this.scrollDir) this.scrollDir = dir;
    });

    document.documentElement.style.scrollPaddingTop = `calc(var(--header-height, 100px) + var(--g-gap))`;

    document.documentElement.style.setProperty(
      '--header-height',
      `${this.$el.offsetHeight}px`
    );
  },
  header: {
    [':style']() {
      return {
        ['--offset']: this.offset // accounts for items placed above the header (i.e. alerts)
      };
    },
    ['x-trap.inert.noscroll']() {
      return this.hasOpen;
    },
    [':class']() {
      return {
        open: this.hasOpen,
        hidden: this.pastThreshold && this.scrollDir !== -1,
        top: this.currentScroll < 100
      };
    },
    ['@scroll.window.debounce.250ms']() {
      this.currentScroll = window.pageYOffset;
    },
    ['@scroll.window.throttle.250ms']() {
      this.currentScroll = window.pageYOffset;

      this.offset = `${Math.max(
        0,
        this.$el.offsetTop +
          this.$el.offsetParent.offsetTop -
          window.pageYOffset
      )}px`;
    }
  },
  sentinel: {
    [':style']() {
      return {
        height: '5vh',
        pointerEvents: 'none',
        position: 'absolute',
        width: '100%',
        zIndex: '-1'
      };
    },
    ['x-intersect:enter']() {
      this.pastThreshold = false;
    },
    ['x-intersect:leave']() {
      this.pastThreshold = true;
    }
  }
});

export const headerMenu = () => ({
  open: false,
  init() {
    Alpine.effect(() => this.$el.classList.toggle('open', this.open));
    Alpine.effect(() => (this.hasOpen = this.open));
  },
  toggle: {
    ['@click']() {
      this.open = !this.open;
    },
    [':aria-label']() {
      return `${this.open ? 'Close' : 'Open'} Menu`;
    }
  },
  overlay: {
    [':class']() {
      return { open: this.open };
    },
    [':style']() {
      return {
        ['--scrollbar-width']: `${
          window.innerWidth - document.documentElement.clientWidth
        }px`
      };
    },
    ['x-transition.opacity.duration.250ms']: undefined,
    ['x-show']() {
      return this.open;
    },
    ['@click.self']() {
      this.open = false;
    }
  },
  listen() {
    this.$el.addEventListener('closeMenu', (event) => {
      this.open = false;
    });
  }
});

export default init;
