import instantsearch from 'instantsearch.js';

import { singleIndex } from 'instantsearch.js/es/lib/stateMappings';

import { default as searchClient } from './client.js';

import Icon from '../../utils/icon.js';

import closeIconUrl from 'url:../../assets/svgs/close.svg';
import arrowRightIconUrl from 'url:../../assets/svgs/arrow-right.svg';

const icons = {
  'arrow-right': Icon('arrow-right', undefined, {
    'arrow-right': arrowRightIconUrl
  }),
  close: Icon('close', undefined, {
    close: closeIconUrl
  })
};

import { date as formatDate } from '../../utils/dates.js';

import { searchBox, configure } from 'instantsearch.js/es/widgets';

import {
  connectInfiniteHits,
  connectRefinementList,
  connectCurrentRefinements,
  connectMenu
} from 'instantsearch.js/es/connectors';

import { filters, engagementMode, pagination, results, searchInput } from './search.module.scss';
import { tag, activeTag, tagList } from './resource.module.scss';

import { imageHtml } from '../../_includes/_partials/image.js';

export const init = (Alpine) => {
  Alpine.data('resourceSearch', Component);

  const hashPrefix = `resource=`

  function Component() {
    const indexName = 'stories';

    const search = instantsearch({
      searchClient,
      indexName,
      insights: true,
      future: {
        preserveSharedStateOnUnmount: true
      },
      routing: {
        stateMapping: singleIndex(indexName)
      }
    });

    return {
      facets: new Map(),
      classes: {
        filters,
        pagination
      },
      init() {
        function isResourceNavigation(url, event) {
          if (
            !event.hashChange &&
            new URL(url).pathname.match(new RegExp(`^/stories/.+`))
          ) {
            return true;
          }

          return isExternalResourceNavigation(...arguments);
        }

        function isExternalResourceNavigation(url, event) {
          if (
            event.type === 'navigate' &&
            url.hash.startsWith(`#${hashPrefix}`) &&
            event.destination.getState()?.content
          ) {
            return true;
          }
        }

        this.$nextTick(() => {
          this.$dispatch('modal:register', isResourceNavigation);
        });
      },
      get results() {
        return this.hits.items;
      },
      get totalResults() {
        return this.hits.results?.nbHits || 0;
      },
      get displayedResults() {
        return this.results?.length || 0;
      },
      get hasResults() {
        return this.totalResults > 0;
      },
      get renderState() {
        return this.search.renderState[this.search.indexName]
      },
      hits: {},
      search,
      EngagementModeControl,
      SearchBox,
      CurrentTags,
      Hits,
      Hit,
      PrevButton: () => ({
        '@click': 'hits.showPrevious',
        'x-show': '!hits.isFirstPage'
      }),
      NextButton: () => ({
        '@click': 'hits.showMore',
        'x-show': '!hits.isLastPage'
      }),
      Filters: () => ({
        ':class': () => filters
      }),
      Pagination: () => ({
        ':class': () => pagination
      }),
      Tag: () => ({
        'x-html': `\`<span>\${term.value}</span> ${icons.close}\``,
        '@click.prevent': 'refine(term)',
        ':class': `{
          ['${tag}']: true,
          ['${activeTag}']: true
        }`,
        ':title': 'term.value',
        ':href': `renderState?.refinementList?.[term.attribute]?.createURL(term.value)`,
        '@click.prevent': `renderState?.refinementList?.[term.attribute]?.refine(term.value)`
      }),
      FacetToggle: (attribute, value) => ({
        'x-data'() {
          return {
            value,
            createURL: undefined,
            refine: undefined,
            attribute: undefined,
            init() {
              const setAttribute = () => {
                this.attribute = this.$data.renderState.attribute?.[attribute] || this.$data.renderState.menu?.[attribute];
              };

              setAttribute();
              this.$data.search.on('render', setAttribute);

              Alpine.effect(() => {
                this.createURL = this.attribute.createURL;
              });

              Alpine.effect(() => {
                this.refine = this.attribute.refine;
              });
            }
          };
        },
        'x-text': `value`,
        ':href': `createURL(value)`,
        '@click.prevent': `refine(value)`
      })
    };
  }

  function EngagementModeControl() {
    const values = new Map();

    const transformItems = (items) => {
      items.forEach((item) => {
        values.set(item.value, item);
      });

      return Array.from(values.values()).map((item) => ({
        ...item,
        canRefine: items.some(({ value }) => value === item.value)
      }));
    };

    const customConnectMenu = connectMenu(
      ({ items, widgetParams, refine }, isFirstRender) => {
        const {
          attribute,
          container,
          defaultLabel = 'All',
          defaultItems = []
        } = widgetParams;

        if (isFirstRender) {
          container.addEventListener('change', ({ target }) => {
            refine(target.value);
          });

          items = transformItems(defaultItems);
        }

        const defaultItem = {
          value: '',
          label: defaultLabel,
          isRefined: items?.every(({ isRefined }) => !isRefined)
        };

        container.innerHTML = [defaultItem, ...items]
          .map(
            ({ value, label, isRefined = false, canRefine = true }) =>
              `<label>
                <input
                  type="radio"
                  name="${attribute}"
                  value="${value}"
                  ${isRefined ? `checked` : ``}
                  ${!canRefine ? 'disabled' : ``}
                />
                ${label}
              </label>`
          )
          .join('');
      }
    );

    return {
      class: engagementMode,
      'x-data'() {
        return {
          init() {
            this.$data.search.addWidgets([this.widget]);
          },
          widget: customConnectMenu({
            container: this.$el,
            attribute: 'engagementMode',
            sortBy: ['name'],
            transformItems,
            defaultItems: ['Read', 'Watch', 'Play'].map((value) => ({
              value,
              label: value
            }))
          })
        };
      }
    };
  }

  function SearchBox() {
    return {
      ':class': () => searchInput,
      'x-data'() {
        return {
          init() {
            this.$data.search.addWidgets([
              configure({ advancedSyntax: true }),
              this.widget
            ]);
          },
          widget: searchBox({
            container: this.$el,
            placeholder: 'Search stories for...',
            queryHook: Alpine.debounce(function (query, search) {
              search(query);
            }, 250)
          })
        };
      }
    };
  }

  function Hits() {
    return {
      'class': results,
      'x-data'() {
        const { $data } = this;

        const tagAttributes = [
          'relationships.creators',
          'relationships.objects',
          'relationships.departments',
          'relationships.events',
          '_tags',
        ]

        const attributes = {}
        const _terms = new Map()

        function onRefine() {
          $data.renderState.currentRefinements.widgetParams.container.scrollIntoView({ block: 'start' })
        }

        return {
          _terms,
          attributes,
          init() {
            const customInfiniteHits = connectInfiniteHits((renderOptions) => {
              const { items } = renderOptions

              items.forEach((item) => {
                item.terms = tagAttributes.reduce((acc, attribute) => {
                  item[attribute]?.forEach((value) => {
                    if (attributes[attribute].has(value)) {
                      acc.push(attributes[attribute].get(value))
                    }
                  })

                  return acc
                }, [])
              })

              $data.hits = { ...renderOptions, items }
            });

            const customRefinementList = connectRefinementList(({ items, refine, createURL, widgetParams }, isFirstRender) => {
              const { attribute } = widgetParams

              if (isFirstRender) {
                attributes[attribute] = new Map()
              }

              const attributeValues = attributes[attribute]

              items.forEach((item) => {
                let { value } = item

                if (!attributeValues.has(value)) {
                  const key = `${attribute}: ${value}`

                  const term = Alpine.reactive({
                    ...item,
                    key,
                    refine: () => {
                      refine(value)
                      onRefine()
                    },
                    createURL: () => createURL(value)
                  })

                  attributeValues.set(value, term)
                  _terms.set(key, term)
                }
              })

              attributeValues.forEach((term, key) => {
                let { count = undefined, isRefined = false } = items.find(({ value }) => value === key) || {}

                Object.assign(term, {
                  attribute,
                  count,
                  isRefined,
                  available: (count > 2 || isRefined),
                  url: term.createURL()
                })
              })
            })

            document.addEventListener(
              'click',
              function (event) {
                let tagLink = document.querySelector('.modal').contains(event.target)
                  && event.target.closest(`a.${tag}`)?.href;

                if (tagLink) {
                  event.preventDefault();

                  navigation.navigate(tagLink, {
                    history: 'push',
                    state: {
                      modal: false
                    }
                  });
                }
              },
              { capture: true }
            );

            $data.search.addWidgets([
              ...tagAttributes.map(attribute =>
                customRefinementList({
                  attribute,
                  sortBy: ['count:desc', 'name:asc'],
                  operator: 'and',
                  limit: 1000
                })
              ),
              customInfiniteHits({
                container: this.$el,
                transformItems: (items) => items.map((hit) => {
                  let {
                    publishedAt,
                    image,
                    lqip,
                    relationships,
                    imageCaption,
                    title,
                    _highlightResult
                  } = hit;

                  const {
                    events,
                    creators,
                    departments,
                    objects
                  } = relationships || {}

                  if (
                    hit.engagementMode === 'Play' &&
                    creators?.length > 0 &&
                    creators.every(
                      (name) => !hit._highlightResult.title.value.includes(name)
                    )
                  ) {
                    let creatorNames = new Intl.ListFormat('en-AU', {
                      style: 'short',
                      type: 'conjunction'
                    })
                      .format(creators)
                      .replace(' and ', ' &amp; ');

                    hit._highlightResult.title.value += ` <br><span>${creatorNames}</span>`;
                  }

                  return {
                    ...hit,
                    title,
                    _highlightResult,
                    _createdAt: new Date(publishedAt * 1000),
                    image: {
                      url: image,
                      metadata: { lqip },
                      caption: imageCaption
                        ? [
                            {
                              _type: 'block',
                              children: [{ _type: 'span', text: imageCaption }]
                            }
                          ]
                        : null
                    },
                    ['relationships.events']: events,
                    ['relationships.creators']: creators,
                    ['relationships.departments']: departments,
                    ['relationships.objects']: objects
                  };
                })
              })
            ]).start();
          }
        };
      }
    };
  }

  function CurrentTags() {
    return {
      'class': tagList,
      'x-data'() {
        const customCurrentRefinements = connectCurrentRefinements((renderOptions) => {
          const { items } = renderOptions

          Object.assign(this.$data, {
            items: items.filter(({ attribute }) => attribute == '_tags' || attribute.startsWith('relationships.')).flatMap(({ refinements }) => refinements).sort(({ value: a }, { value: b }) => a.localeCompare(b))
          })
        });

        return {
          items: [],
          createURL: undefined,
          refine: undefined,
          init() {
            this.$data.search.addWidgets([
              this.widget
            ]);
          },
          widget: customCurrentRefinements({
            container: this.$el,
            includedAttributes: ['_tags', 'relationships.creators', 'relationships.objects', 'relationships.departments', 'relationships.events']
          })
        }
      }
    }
  }

  function Hit() {
    return {
      'x-data': `resource({ ...item, item, tags: [] })`,
      'x-init'() {
        this.$el.querySelectorAll('a').forEach((link) => {
          if (link.hostname !== window.location.hostname) {
            link.setAttribute('target', '_blank');
          }
        });

        this.$el.addEventListener('click', (event) => {
          if (event.target.closest('a').href.includes(this.url)) {
            this.$data.hits.sendEvent('click', this.$data.item, 'Hit Clicked');
          }
        });

        Alpine.effect(() => {
          this.title =
            this.item._highlightResult.title?.value ?? this.item.title;
        });

        Alpine.effect(() => {
          this.summary =
            this.item._snippetResult?.summary?.value ??
            this.item._highlightResult?.summary?.value ??
            this.item.summary;
        });

        return;

        const {
          $el,
          engagementMode,
          terms,
          url,
          objectID,
          summary,
          _createdAt,
          title,
          image
        } = this;

        let external,
          resourceHash = `#${hashPrefix}${objectID}`

        if (url.match(/youtube/)) {
          external = youtubeResource();
        } else if (
          engagementMode === 'Play' ||
          url.match(/https:\/\/collection\.qagoma\.qld\.gov\.au/)
        ) {
          external = linkResource();
        } else if (url.match(/https:\/\/apps\.qagoma\.qld\.gov\.au/)) {
          external = iframeResource();
        }

        if (external) {
          if (location.hash === resourceHash) {
            let destinationUrl = new URL(location.href)

            destinationUrl.hash = '#'

            navigation.navigate(destinationUrl, {
              history: 'replace'
            }).finished.then(() => {
              openResource()
            })
          }

          $el.addEventListener('click', (event) => {
            if (event.target.closest('a').href.includes(url)) {
              event.preventDefault()
              openResource()
            }
          })
        }

        function openResource() {
          let destinationUrl = new URL(navigation.currentEntry.url)
          destinationUrl.hash = resourceHash

          navigation.navigate(destinationUrl, {
            history: 'push',
            state: {
              content: `<div class="stories container">${external}</div>`
            }
          })
        }

        function youtubeResource() {
          return `${header(
            `<iframe width="1920" height="1080" src="https://www.youtube.com/embed/${objectID}?si=QLHCGSltTc_Mf9l2" title="${title}" frameborder="0" allow="compute-pressure; accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowfullscreen></iframe>`
          )}

          <p style="white-space:pre-wrap">${summary
            .replaceAll(/\n\n+/g, '</p><p style="white-space:pre-wrap">')
            .replaceAll(
              /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/g,
              `<a href="$&" target="_blank">$&</a>`
            )
            .replaceAll(
              /(?:^|\B)#((?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r))/g,
              `<a href="https://www.youtube.com/hashtag/$1" target="_blank">$&</a>`
            )}
          </p>

          ${footer()}`;
        }

        function iframeResource() {
          return `${header()}

          <iframe width="1920" height="1080" src="${url}" title="${title}" frameborder="0" allow="compute-pressure; accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowfullscreen></iframe>

          ${footer()}`
        }

        function linkResource() {
          let link = new URL(url),
            cta;

          if (engagementMode === 'Play') {
            if (terms?.some(({ value }) => value === 'Game')) {
              cta = 'Launch game';
            } else {
              cta = 'Download activity';
            }
          } else if (link.hostname === 'collection.qagoma.qld.gov.au') {
            link.hash = 'block-qagoma-theme-content';
            cta = 'Continue reading on QAGOMA’s Collection website';
          }

          return `
          ${header(
            image &&
              `<section class="hero-image">
                ${imageHtml({ ...image, height: 882, width: 1800 })}
              </section>`
          )}

          ${summary ? `<p class="truncate">${summary}</p>` : ``}

          ${readMore(link, cta)}

          ${footer()}`;
        }

        function readMore(link, cta = 'Read more') {
          return `<a href="${link}" target="_blank" class="button">
            ${cta}
            ${icons['arrow-right']}
          </a>`;
        }

        function header(content = '') {
          return `<header class="page-header">
            <h1 class="heading-xl">${title}</h1>

            ${content}

            <time style="text-align:center">
              ${formatDate(_createdAt, { showTime: false })}
            </time>
          </header>`
        }

        function footer() {
          return `<footer class="container">
            <aside class="emailSignup" id="c_emailSignupModal">
              <header>
                <h1>Stories highlights delivered to your&nbsp;inbox</h1>
              </header>

              <form action="/_/subscribe/" x-data="emailSignup()" x-bind="form">
                <div x-show="message" class="emailSignup__message" :class="success &amp;&amp; 'success'" style="display: none;">
                  <span x-html="message"></span>
                </div>

                <div class="emailSignup__fields">
                  <div>
                    <label for="fname">First Name</label>
                    <input type="text" id="fname" name="fname" autocomplete="given-name" required="">
                  </div>

                  <div>
                    <label for="email">Email</label>
                    <input type="email" id="email" name="email" required="">
                  </div>
                </div>

                <input type="hidden" name="subscriptionId" value="87582492">

                <button class="button" type="submit">
                  Sign Up <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" role="presentation">
                <use href="/assets/images/chevron.e939939a.svg#path"></use>
              </svg>
                </button>
              </form>
            </aside>
          </footer>`
        }
      },
      'x-bind': 'Card'
    };
  }
};
