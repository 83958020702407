﻿module.exports = function ({ title, query, blockHelpers, ...data }) {
  return `<section x-data="featuredEvents(\`${query}\`)" x-show="events.length" ${blockHelpers.id(
    data
  )}>
    ${
      title
        ? `<header class="section-header">
        <h2 class="heading-lg">${title}</h2>
      </header>`
        : ``
    }
    
    <ul class="grid grid-4 card-grid">
      <template x-for="event in events" :key="event._id">
        <li class="card" x-bind="card(event)"></li>
      </template>
    </ul>
  </section>`;
};
