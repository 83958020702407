module.exports = function ({
  playlist,
  videos,
  type,
  title,
  blockHelpers,
  ...data
}) {
  return `<section class="block-videoShowcase" x-data='videoShowcase(${JSON.stringify(
    { playlist, videos, type }
  )})' ${blockHelpers.id(data)}>

  <div class="block-videoShowcase__wrapper">
    <header class="section-header">
      <h1 class="heading-lg">${title}</h1>
      <template x-if="link">
        <a x-bind="link"></a>
      </template>
    </header>

    <template x-if="videos">
      <main>
        <template x-if="selected">
          <div x-bind="player"></div>
        </template>
      </main>
    </template>
          
    <template x-if="videos">
      <ul>
        <template x-for="video in videos">
        <li>
          <button @click="selected=video" :class="selected && video.videoId === selected.videoId ? 'selected' : ''">
            <h1 x-text="video.title"></h1>
            <img x-bind:src="video.thumbnail">
          </button>
        </li>
        </template>
      </ul>
    </template>
  </div>
</section>`;
};
