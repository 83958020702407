import carousel from '../carousel';

export const init = (Alpine) => {
  Alpine.data('imageCarousel', component);
  Alpine.data('carousel', carousel);
};

export const component = () => ({});

export default init;
