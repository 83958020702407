import DefaultField from './DefaultField';

export default function AjaxCheckboxField(props) {
  const defaults = DefaultField(props);

  return {
    ...defaults,
    separator: ' • ',
    input: {
      ...defaults.input,
      type: 'text',
      'x-show': false,
      '@invalid': 'showValidationMessage = true',
      '@change': 'showValidationMessage = false'
    },
    init() {
      defaults.init();
      // TODO: Move logic from _includes/_partials/form.js
    },
    get value() {
      return [this.options.map(({ title }) => title), this.selected]
        .reduce((a, b) => a.filter((option) => b.includes(option)))
        .join(this.separator);
    },
    selected: [],
    options: [],
    groups: '',
    loading: false
  };
}
