const collectionOnlineUrl = new URL('https://collection.qagoma.qld.gov.au');
const apiUrl = new URL(`/api`, collectionOnlineUrl);
import { card } from '../../_includes/_partials/card';

export const init = (Alpine) => {
  Alpine.data('collectionShowcase', component);
};

const arrowRight = new URL(`/assets/svgs/arrow-right.svg`, import.meta.url);

const request = async (endpoint, params) => {
  const request = await fetch(`${apiUrl}/${endpoint}/${params.objectId}`);
  const response = await request.json();
  return response[0];
};

const getRandom = (arr, n) => {
  var result = new Array(n),
    len = arr.length,
    taken = new Array(len);
  if (n > len) return arr;
  while (n--) {
    var x = Math.floor(Math.random() * len);
    result[n] = arr[x in taken ? taken[x] : x];
    taken[x] = --len in taken ? taken[len] : len;
  }
  return result;
};

const getCollectionItems = async (_itemIds) => {
  const itemIds = getRandom(_itemIds, 4);

  let items = [];

  for (let i = 0; i < itemIds.length; i++) {
    const itemId = itemIds[i];

    const response = await request(`objects/feed/object`, {
      objectId: itemId
    });

    console.log(response);

    items.push(response);
  }

  return items.map((item) => {
    return {
      image: new URL(item.image_thumb_480, collectionOnlineUrl),
      title: item.object_title,
      creator: item.creator_list,
      link: item.url,
      date: item.date_created,
      creatorLink: item.creator_link
        .replace(/^.*(<a.*\/a>).*$/, '$1')
        .replace(
          /href=["']([^"']+?)/,
          (match, path) =>
            `target="_blank" ` +
            match.replace(path, new URL(path, collectionOnlineUrl))
        )
    };
  });
};

export const component = ({
  title = null,
  type = 'posts',
  tag: tagName = null,
  posts: postIds = null,
  collectionItems: collectionItems = null
}) => {
  return {
    title,
    type,
    collectionItems,
    tagName,
    postIds,
    tag: null,
    posts: null,
    items: [],
    init() {
      Alpine.effect(async () => {
        this.items = await getCollectionItems(this.collectionItems);
      });
    },
    card() {
      return {
        ['x-html']() {
          const { image, link, title, date, creatorLink } = this.item;

          return card({
            ...this.item,
            url: link,
            title: `<em>${title}</em> <span>${date}</span>`,
            image: { url: image },
            wrap: false,
            details: {
              Creator: creatorLink
            },
            icons: {
              'arrow-right': arrowRight
            }
          });
        }
      };
    }
  };
};

export default init;
