const { imageHtml } = require('../_partials/image.js');
const { eventUrl } = require('../../$content/events');

module.exports = function ({
  image,
  title,
  text,
  link,
  links,
  blockHelpers,
  ...data
}) {
  if (link?.reference) {
    if (link.reference?._type === 'event') {
      link.url = eventUrl(link.reference);
    } else if (link.reference?._ref || link.reference?._id) {
      let page = links[link?.reference?._ref || link?.reference?._id];
      if (page) link.url = page;
    }
  }

  if (link.url && link.calendarFilters) {
    link.url = [
      link.url,
      new URLSearchParams(
        link.calendarFilters.map(({ _type, name }) => [
          _type.split('.').pop().replace('Feature', '').toLowerCase(),
          name
        ])
      )
    ].join('?');
  }

  return `<section class="block-callToAction ${blockHelpers.oddOrEven(
    'imageBlock'
  )}" ${blockHelpers.id(data)}>
    ${image ? imageHtml({ ...image, width: 885, height: 580 }) : ``}

    <div>
      <h1 class="heading-lg">${title}</h1>
      <p>${text}</p>
      ${
        link?.url
          ? `<a class="button" href="${link.url}">${link?.text || link.url}</a>`
          : ``
      }
    </div>
  </section>`;
};
