const Icon = require('../../utils/icon.js');

module.exports = function ({
  title,
  id,
  blockHelpers,
  ...data
}){
  return `<section class="block-exhibitionResourceFeed" x-data="exhibitionResourceFeed(\`${id}\`)" x-show="creators.length" ${blockHelpers.id(data)}>
    <div x-cloak class="block-exhibitionResourceFeed__wrapper">
      <header class="section-header">
        <h2 class="heading-lg">${title}</h2>
        <a :href="url+'/artists'" class="cta-see-more">See all Artists ${Icon('arrow-right')}</a>
        
        <div class="exhibitionResourceFeed__search_bar">
          <div class="exhibitionResourceFeed__search">
            <input placeholder="Search for..." x-model="sterm" type="text">
            <button class="search-submit">${Icon('search')}</button> 
          </div>

          <div class="exhibitionResourceFeed__filter">
            <button class="button button-sm" :class="svenue=='qagoma' && 'selected'" @click="svenue='qagoma'">QAGOMA</button>
            <button class="button button-sm" :class="svenue=='qag' && 'selected'" @click="svenue='qag'">QAG</button>
            <button class="button button-sm" :class="svenue=='goma' && 'selected'" @click="svenue='goma'">GOMA</button>
          </div>
        </div>
      </header>

      <ul class="grid card-grid" style="--column-count:5">
        <template x-for="creator in filteredCreators" :key="creator._id">
          <li class="card" :class="!creator.showInListing && 'hideCreator'" x-bind="card(creator)"></li>
        </template>
      </ul>

      <button class="button" x-show="!smore" @click="smore=true">Load More</button>
    </div>
  </section>`;
};