﻿import svgInject from '@iconfu/svg-inject';

export const init = (Alpine) => {
  Alpine.data('proportionalImageGroup', proportionalImageGroup);
};

export const proportionalImageGroup = ({
  selector = 'img, svg',
  widthBase = 70,
  scaleFactor = 0.525
} = {}) => ({
  init() {
    const fontSize = getFontSize(this.$el);

    const beforeLoad = (img) => {
      new Promise((resolve) => {
        if (img.complete) resolve();
        else img.onload = resolve;
      })
        .then(() => {
          let w = img.naturalWidth ?? img.clientWidth,
            h = img.naturalHeight ?? img.clientHeight,
            ratio = w / h,
            weight = 1 - calculateImageWeight(img, w, h) / ratio;

          img.style.maxWidth =
            Math.pow(ratio, scaleFactor) *
              ((widthBase / fontSize) * Math.pow(weight, 4)) +
            'em';
          img.style.maxHeight = 'none';
        })
        .catch(console.error);
    };

    svgInject(this.$el.querySelectorAll(selector), { beforeLoad });
  }
});

export default init;

const calculateImageWeight = (image, w, h) => {
  const c = document.createElement('canvas').getContext('2d');

  c.drawImage(image, 0, 0, w, h);

  const { data } = c.getImageData(0, 0, w, h);

  return data.reduce((sum, i) => sum + i, 0) / data.length / 255;
};

const getFontSize = (el) =>
  parseInt(window.getComputedStyle(el).getPropertyValue('font-size'));
