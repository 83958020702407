import EmblaCarousel from 'embla-carousel';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';

EmblaCarousel.globalOptions = { loop: false };

import arrowRight from 'bundle-text:/src/assets/svgs/arrow-right.svg';
import arrowLeft from 'bundle-text:/src/assets/svgs/arrow-left.svg';

export const init = (Alpine) => {
  Alpine.data('carousel', component);
  Alpine.data('carouselPopup', carouselPopup);
};

export const carouselPopup = () => ({
  initialized: false,
  open: false,
  trigger: {
    type: 'button',
    ['@click.once']() {
      this.initialized = true;
    },
    ['@click']() {
      this.$nextTick(() => (this.open = !this.open));
    }
  },
  template: {
    ['x-if']() {
      return this.initialized;
    }
  },
  wrapper: {
    [':class']() {
      return 'carousel-popup';
    },
    ['x-transition.opacity.duration.250ms']: undefined,
    ['x-show']() {
      return this.open;
    },
    ['x-trap.inert.noscroll']() {
      return this.open;
    },
    ['@click.self']() {
      this.open = false;
    },
    ['@keyup.escape.window']() {
      if (this.open) this.open = false;
    },
    ['@keyup.right.window']() {
      Alpine.evaluate(
        this.$el.querySelector('.carousel'),
        'this.open && carousel.canScrollNext() && carousel.scrollNext()'
      );
    },
    ['@keyup.left.window']() {
      Alpine.evaluate(
        this.$el.querySelector('.carousel'),
        'this.open && carousel.canScrollPrev() && carousel.scrollPrev()'
      );
    }
  }
});

const cancelDownEvent = (e) => e.stopPropagation();

function setupCarousel(el, params) {
  const trackProperties = () => {
    this.canScrollNext = carousel.canScrollNext();
    this.canScrollPrev = carousel.canScrollPrev();
    this.currentSlideIndex = carousel.selectedScrollSnap();
  };

  this.$el.querySelectorAll('figcaption').forEach((selectable) => {
    selectable.addEventListener('mousedown', cancelDownEvent);
    selectable.addEventListener('touchstart', cancelDownEvent);
  });

  const wheelGestures = WheelGesturesPlugin();
  const carousel = EmblaCarousel(el, params, [wheelGestures]);

  carousel.on('init', trackProperties);
  carousel.on('reinit', trackProperties);
  carousel.on('select', trackProperties);

  el.addEventListener('load', () => carousel.reInit(), {
    capture: true
  });

  return carousel;
}

export const component = (params = { id: 'carousel' }) => ({
  carousel: null,
  canScrollNext: undefined,
  canScrollPrev: undefined,
  currentSlideIndex: -1,
  synchronizedCarousel: undefined,
  init() {
    this.synchronizedCarousel = (params) => {
      let resolveSyncWith;

      let syncWith = new Promise((resolve) => {
        resolveSyncWith = resolve;
      }).then((parentCarousel) => {
        return function (child) {
          const syncCarousel = followMainCarousel(parentCarousel, child);

          child.on('init', syncCarousel);
          parentCarousel.on('select', syncCarousel);
        };
      });

      Alpine.effect(() => {
        this.carousel && resolveSyncWith(this.carousel);
      });

      return component({
        ...params,
        syncWith,
        id: this.$id('synchronized-carousel')
      });
    };
  },
  root: {
    async ['x-init']() {
      this.carousel = setupCarousel.bind(this)(this.$el, params);

      if (params.syncWith) {
        const sync = await params.syncWith;
        sync(this.carousel);
      }
    },
    [':id']() {
      return this.$id(params.id);
    },
    ['x-ref']: params.id,
    [':class']() {
      return 'embla carousel';
    },
    [':tabindex']() {
      return 0;
    },
    ['@keyup.right']() {
      this.carousel.scrollNext();
    },
    ['@keyup.left']() {
      this.carousel.scrollPrev();
    }
  },
  viewport: {
    [':class']() {
      return 'embla__container carousel__container';
    },
    [':id']() {
      return this.$id(params.id, 'viewport');
    },
    ['x-ref']: 'viewport'
  },
  slide: {
    [':class']() {
      return 'embla__slide carousel__slide';
    },
    ['@focusin']() {
      if (this.$refs.viewport) this.$refs.viewport.scrollLeft = 0;
      if (this.$refs[params.id]) this.$refs.carousel.scrollLeft = 0;
      this.carousel.scrollTo(this.carousel.slideNodes().indexOf(this.$el));
    }
  },
  buttons: {
    [':class']() {
      return 'carousel__buttons';
    }
  },
  dirButton(dir) {
    return {
      type: 'button',
      [':class']() {
        return `carousel__button carousel__button--${dir}`;
      },
      [':disabled']() {
        return dir === 'prev' ? !this.canScrollPrev : !this.canScrollNext;
      },
      ['@click']() {
        dir === 'prev'
          ? this.carousel.scrollPrev()
          : this.carousel.scrollNext();
      },
      ['x-html']() {
        return `${
          dir === 'prev' ? arrowLeft : arrowRight
        } <span class="sr-only">${dir}</span>`;
      }
    };
  }
});

export const onThumbClick = (mainCarousel, thumbCarousel, index) => () => {
  if (!thumbCarousel.clickAllowed()) return;
  mainCarousel.scrollTo(index);
};

export const followMainCarousel = (mainCarousel, thumbCarousel) => () => {
  thumbCarousel.scrollTo(mainCarousel.selectedScrollSnap());
  selectThumbBtn(mainCarousel, thumbCarousel);
};

const selectThumbBtn = (mainCarousel, thumbCarousel) => {
  const previous = mainCarousel.previousScrollSnap();
  const selected = mainCarousel.selectedScrollSnap();
  const slides = thumbCarousel.slideNodes();
  if (slides[previous]) slides[previous].classList.remove('is-selected');
  if (slides[selected]) slides[selected].classList.add('is-selected');
};

export default init;
