import { client as sanityClient } from '../../utils/sanity/index.js';

import { card } from '../../_includes/_partials/card.js';
import { date } from '../../utils/dates.js';

import * as classes from './resource.module.scss';

import Icon from '../../utils/icon.js';

import closeIconUrl from 'url:../../assets/svgs/close.svg';

const icons = {
  close: Icon('close', undefined, {
    close: closeIconUrl
  })
};

export const init = (Alpine) => {
  console.log(classes)
  Alpine.data('resource', Component);

  function Component(data) {
    return {
      ...data,
      relatedResources: undefined,
      fetchRelatedResources: function ({ count = 2 } = {}) {
        if (!this.$data.relatedResources) {
          this.$data.relatedResources = []

          const relatedResourcesQuery = `*[_type == "resource" && _id != $id][
  references([...$tags, ...$events, ...$departments]) ||
  $creators match relationships.creators ||
  $objects match relationships.objects
]{
  ...,
  "matches": math::sum([
    count(tags[_ref in $tags]) * 0.5,
    count(relationships.events[_ref in $events]),
    count(relationships.departments[_ref in $departments]),
    count(relationships.creators[@ in $creators]) * 2,
    count(relationships.objects[@ in $objects]) * 2
  ])
}|order(matches desc, _createdAt desc)[0...$count]{
  _createdAt,
  title,
  "url": "/stories/" + slug.current,
  image{..., ...asset->},
  "summary": pt::text(coalesce(summary, content[_type == "block"][0])),
  ...relationships{
    ...,
    creators|order((^.^.title match @) desc, @ asc),
    objects|order((^.^.title match @) desc, @ asc),
    events[]->{
      _type,
      _id,
      title,
      subtitle,
      start,
      end,
      priority
    }|order((^.^.title match @) desc, start desc, end desc, priority desc, title asc),
    departments[]->|order(name asc)
  },
  "tags": tags[]->{
    ...,
    _id,
    name,
    "count": count(*[_type == "resource" && references(^._id)])
  }|order(count desc, name asc)[count > 3]
}`;

          const {
            id,
            creators = [],
            objects = [],
            events = [],
            departments = [],
            tags = []
          } = this.$data;

          let params = Object.entries({
            id,
            creators,
            objects,
            events,
            departments,
            tags,
            count
          }).reduce(
            (acc, [k, value]) => {
              if (Array.isArray(value)) {
                value = value?.map((value) => value?._id ?? value)
              }

              return Object.assign(acc, {
                [k]: value
              })
            },
            {}
          );

          return sanityClient.withConfig({ token: false, useCredentials: true, withCredentials: true }).fetch(relatedResourcesQuery, params).then((result) => {
            if (result) {
              this.$data.relatedResources.push(...result)
            }
          })
        }

        return this.$data.relatedResources
      },
      Resource: {
        ':class': classes.resource
      },
      Card: {
        ':class': '`card`',
        'x-html': () => {
          const {
            _createdAt,
            engagementMode,
            title,
            image,
            url,
            summary
          } = this.$data;

          return card({
            pretitle: [
              `<template x-if="FacetToggle">
                <a x-bind="FacetToggle('engagementMode', '${engagementMode}')"></a>
              </template>`,
              `<time>${date(_createdAt, {
                showTime: false,
                showDay: false
              })}</time>`
            ].join(' • '),
            title,
            image,
            url,
            content: [
              summary && `<div class="card__summary truncate">${summary}</div>`,
              `<template x-bind="Tags"></template>`
            ].filter(Boolean).join(' '),
            wrap: false
          }).replace(' crossorigin="anonymous"', '')
        }
      },
      RelatedResources: (params) => {
        const template = `<section>
  <h1>Related Stories</h1>

  <ul class="grid card-grid ${classes.relatedResources}">
    <template x-for="relatedResource in resources">
      <li x-data="resource(relatedResource)" x-bind="Card"></li>
    </template>
  </ul>
</section>`;

        return {
          'x-data': JSON.stringify({ resources: [] }),
          'x-model': 'relatedResources',
          'x-modelable': 'resources',
          'x-if': `resources.length`,
          'x-init'() {
            defaultTemplate.bind(this)(template);
            console.log(this);
            this.fetchRelatedResources(params);
          }
        };
      },
      Tags: () => {
        const template = `<ul class="${classes.tagList}">
  <template x-for="term in terms" :key="term.key">
    <template x-if="term.available !== false">
      <li>
        <a x-bind="Tag"></a>
      </li>
    </template>
  </template>
</ul>`;

        return {
          'x-if': 'terms.length',
          'x-init'() {
            defaultTemplate.bind(this)(template);
          }
        }
      },
      Tag: {
        ':class': `{
          ['${classes.tag}']: true,
          ['${classes.activeTag}']: term.isRefined
        }`,
        '@click': `if(term.refine) { $event.preventDefault(), term.refine() }`,
        ':href': `term.url`,
        ':title': 'term.value',
        'x-html': `\`<span>\${term.value}</span> ${icons.close}\``
      }
    };
  }

  function parseTemplate(string) {
    return new DOMParser().parseFromString(string, 'text/html').body.firstElementChild
  }

  function defaultTemplate(string) {
    if (!this.$el.content.firstElementChild) {
      this.$el.content.replaceChildren(parseTemplate(string))
    }
  }
};