﻿const { imageHtml } = require('./image.js');
const icon = require('../../utils/icon.js');

const card = ({ wrap = true, useCta = false, content, ...props }) => {
  const { image, details } = props;
  const contents = [
    image && CardImage(props),
    CardHeader(props),
    content,
    details && CardDetails(props),
    useCta && CardCallToAction(props)
  ]
    .filter(Boolean)
    .join(` `);

  if (wrap) return `<li class="${getClasses(props)}">${contents}</li>`;

  return contents;
};

const getClasses = ({ classes = [] }) =>
  ['card', ...classes].filter(Boolean).join(' ');

const CardImage = ({ url, tag, image }) => `<a href="${url}" tabindex="-1">
  
    ${tag ? `<span class="card__info">${tag}</span>` : ``}

    ${imageHtml(
      typeof image == 'string'
        ? image
        : {
            ...image,
            width: 885,
            height: 758,
            caption: false,
            classes: 'card__image'
          }
    )}
  </a>`;

const CardHeader = ({ pretitle, url, title }) => `<header>
  ${pretitle ? `<span>${pretitle}</span>` : ``}

  <h1 class="heading-md">
    <a href="${url}">${title}</a>
  </h1>
</header>`;

const CardDetails = ({ details }) => `<ul class="card__details accent">
  ${Object.entries(details)
    .map(
      ([key, value]) =>
        `<li class="card__${key.toLowerCase()}"><span class="sr-only">${key}</span> ${value}</li>`
    )
    .join(``)}
</ul>`;

const CardCallToAction = ({
  url,
  cta = 'Read On',
  icons
}) => `<a href="${url}" class="card__cta" target="_blank" tabIndex="-1">
  ${cta} ${icon('arrow-right', false, icons)}
</a>`;

exports.card = card;
