const { toHTML } = require('../../../src/utils/sanity/index.js');

module.exports = function ({ title, text, blockHelpers, ...data }) {
  if (!text) return;

  const headings = text.filter(({ style }) => style?.match(/h\d/));

  return `<section class="block-expandedText" ${blockHelpers.id(data)}>
    <div>
      ${title ? `<h1 class="heading-lg">${title}</h1>` : ``}

      <div>
        <div>
          ${text
            .reduce((acc, item) => {
              if (headings.includes(item)) {
                acc.push([item]);
              } else {
                let i = Math.max(0, acc.length - 1);
                if (!acc[i]) acc[i] = [];
                acc[i].push(item);
              }

              return acc;
            }, [])
            .map((blocks) => `<div>${toHTML(blocks, data)}</div>`)
            .join('')}
        </div>
      </div>
    </div>
  </section>`;
};
