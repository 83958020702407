import DefaultField from './DefaultField';

export default function DateField(props) {
  const defaults = DefaultField(props);

  return {
    ...defaults,
    relativeDate: function (modifier = 0) {
      let date = new Date();

      date.setDate(date.getDate() + parseInt(modifier));

      return date.toISOString().split('T')[0];
    }
  };
}
