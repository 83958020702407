const { client: sanity } = require('../utils/sanity/index.js');
const useCache = require('../utils/assetCache');
const fetch = require('node-fetch');

const {
  blocksProjection,
  portableTextProjection,
  parentProjection,
  imageProjection,
  videoProjection
} = require('./_common.js');

const projection = `{
  ...,
  ${parentProjection()},
  "introduction": introduction[]${portableTextProjection},
  "slug": slug.current,
  "blockTypes": content[]._type,
  "navigation": *[_type == "navigation" && references(^._id)]._id,
  ${imageProjection},
  ${videoProjection},
  count(content) > 0 => {
    "blocks": content[]${blocksProjection}
  }
}`;

const query = `*[_type in ['page', 'page.advanced'] && defined(slug) && parent._ref != "7a23ad1a-7f96-40bb-9d3a-b3df11620caa" && !(_id in $excludedIds)]${projection}`;

const params = {
  excludedIds: ['59126779-7b37-4af7-a75a-720e9b8fec77', 'home']
};

const getSlugs = (page, acc = []) => {
  if (page?.slug) {
    acc.unshift(page?.slug);
    return getSlugs(page.parent, acc);
  } else {
    return acc;
  }
};

const transform = async (doc, force = false) => {
  if (!force && doc.transformed) return doc;

  let { hubspotFormId, navigation, content, ...rest } = doc;
  let { _id, title, parent } = doc;
  let id = _id.replace('drafts.', '');

  const result = Object.assign({}, rest, {
    transformed: true,
    url: `/${getSlugs(doc).join('/')}/`,
    tags: ['page'],
    eleventyNavigation: {
      key: id,
      title,
      parent: parent?._id
    }
  });

  if (hubspotFormId) {
    result.form = await fetch(
      `${process.env.QAGOMA_MIDDLEWARE_URL}/crm/forms/${hubspotFormId}`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: 'Bearer k8uzmt8uBJLK6Z4'
        }
      }
    ).then((data) => data.json());

    result.tags.push('form');
  }

  if (navigation)
    result.tags = result.tags.concat(
      navigation.map((x) => x.replace('drafts.', ''))
    );

  return result;
};

module.exports = async (configData) => {
  console.log('Fetching pages');

  let educationResourcePages = (async () => {
    let params = {
      educationResourcesPageId: '7a23ad1a-7f96-40bb-9d3a-b3df11620caa'
    };

    let query = `*[_type == "page" && parent._ref == $educationResourcesPageId]{
      _id,
      "resource": *[_type == "educationResource" && title match ^.title][0]{
        ...,
        ...relatedExhibition->{
          slug,
          "title": coalesce(^.title, title)
        }
      }
    }[defined(resource)]{
      "pageId": _id,
      ...resource
    }{
      pageId,
      _id,
      _type,
      title,
      "slug": slug.current
    }`;

    return await useCache(JSON.stringify({ query, params }), () =>
      sanity.fetch(query, params)
    ).then((response) =>
      response.reduce(
        (x, { pageId, ...rest }) => Object.assign(x, { [pageId]: rest }),
        {}
      )
    );
  })();

  let documents = await (async () =>
    await useCache(JSON.stringify({ query, params }), () =>
      sanity.fetch(query, params)
    ))();

  educationResourcePages = await educationResourcePages;

  documents = await Promise.all(documents.map(transform)).then((documents) => {
    return documents.reduce((documents, doc) => {
      let { parent } = doc;
      let resource = parent?._id && educationResourcePages[parent._id];

      if (resource) {
        Object.assign(doc, {
          parent: {
            ...parent,
            ...resource
          }
        });
      }

      return Object.assign(documents, {
        [doc._id.replace('drafts.', '')]: doc
      });
    }, {});
  });

  console.log(`Fetched ${Object.keys(documents).length} pages`);

  return documents;
};

Object.assign(module.exports, { query, projection, transform, getSlugs });
