export default () =>
  Promise.resolve(window.YouTubeIframeAPIReady).then((ready) => {
    if (!ready) {
      window.YouTubeIframeAPIReady = new Promise((resolve) => {
        if (typeof YT !== 'undefined') resolve(YT);
        window.onYouTubeIframeAPIReady = () => resolve(YT);

        injectScript();
      });
    }

    return window.YouTubeIframeAPIReady;
  });

const injectScript = () => {
  let tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';

  document
    .getElementsByTagName('script')[0]
    .insertAdjacentElement('afterend', tag);
};

export const playerSettings = {
  height: '1080',
  width: '1920',
  playerVars: {
    modestbranding: true,
    playsinline: 1,
    rel: 0
  }
};
