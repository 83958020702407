import DefaultField from './DefaultField';

import AjaxCheckboxField from './AjaxCheckboxField';
import CurrencyField from './CurrencyField';
import DateField from './DateField';
import SecurePayDonationField from './SecurePayDonationField';

export default function Field(props) {
  const { type } = props;

  switch (type) {
    case 'securePayDonation':
      return SecurePayDonationField.bind(this)(props);

    case 'date':
      return DateField.bind(this)(props);

    case 'currency':
      return CurrencyField.bind(this)(props);

    case 'ajaxCheckbox':
      return AjaxCheckboxField.bind(this)(props);

    default:
      return DefaultField.bind(this)(props);
  }
}
