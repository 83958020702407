const collectionOnlineUrl = 'https://collection.qagoma.qld.gov.au';
const Icon = require('../../utils/icon.js');

module.exports = function ({
  title,
  type,
  tag,
  posts,
  collectionItems,
  blockHelpers,
  ...data
}) {
  return `<section class="block-collectionShowcase" x-cloak x-data='collectionShowcase(${JSON.stringify(
    { title, type, tag, posts, collectionItems }
  )})' ${blockHelpers.id(data)}>

  <header class="section-header">
    <h1 class="heading-lg">${title}</h1>
    <a :href="'${collectionOnlineUrl}'" target="_blank" class="cta-see-more">
      View the Collection ${Icon('arrow-right')}
    </a>
  </header>

  <ul class="grid grid-5 card-grid">
    <template x-for="item in items">
      <li x-bind="card" class="card"></li>
    </template>
  </ul>
</section>`;
};
