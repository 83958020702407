const Icon = require('../../utils/icon.js');

module.exports = function ({ title, type, blockHelpers, ...data }) {
  return `<section class="block-blogShowcase" x-cloak x-data='blogShowcase("${type}", ${JSON.stringify(
    data[type]
  )})' ${blockHelpers.id(data)}>

  <header class="section-header">
    <h1 class="heading-lg">${title}</h1>
    <a href="https://blog.qagoma.qld.gov.au/" :href="link.url" class="cta-see-more">
      <span x-html="link.text">QAGOMA Blog</span>
      ${Icon('arrow-right')}
    </a>
  </header>

  <template x-if="posts.length">
    <ul class="grid grid-4 card-grid">
      <template x-for="post in posts" :key="post.id">
        <li x-bind="card" class="card"></li>
      </template>
    </ul>
  </template>
</section>`;
};
