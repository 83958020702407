const Icon = require('../../utils/icon.js');

module.exports = function ({
  title,
  type,
  tag,
  collection,
  products,
  blockHelpers,
  ...data
}) {
  return `<section class="block-productShowcase" x-data='productShowcase(${JSON.stringify(
    {
      title,
      type,
      tag,
      collection,
      products
    }
  )})' ${blockHelpers.id(data)}>
    <header class="section-header">
      <h1 class="heading-lg">${title}</h1>
      
      <a href="https://qagoma.store/collections/all/${tag
        ?.replaceAll(/\W+/g, '-')
        .toLowerCase()}" class="cta-see-more">
        View more products ${Icon('arrow-right')}
      </a>
    </header>

    <ul class="grid card-grid" style="--column-count:5">
      <template x-for="product in products">
        <li x-bind="card" class="card"></li>
      </template>
    </ul>
  </section>`;
};
