import { easepick, LockPlugin } from '@easepick/bundle';
import calendarStyles from 'url:../assets/styles/easepick.scss';

import {
  projection,
  ticketUrl,
  withUrl,
  withTicketUrl
} from '../$content/events.js';

import { card } from '../_includes/_partials/events.js';

const { client: sanityClient } = require('../utils/sanity/index.js');

const sanity = sanityClient.withConfig({
  useCdn: true,
  useCredentials: false,
  token: false
});

import Icon from '../utils/icon.js';

const icons = {
  chevron: new URL(`/assets/svgs/chevron.svg`, import.meta.url),
  'chevron-down': new URL(`/assets/svgs/chevron-down.svg`, import.meta.url),
  'chevron-left': new URL(`/assets/svgs/chevron-left.svg`, import.meta.url),
  currency: new URL(`/assets/svgs/currency.svg`, import.meta.url),
  info: new URL(`/assets/svgs/info.svg`, import.meta.url)
};

const resolveEvent = function (events) {
  const resolved = events.find(({ _id }) => _id == this._id);

  if (resolved) {
    return Object.assign(this, withTicketUrl(withUrl(resolved)), {
      resolved: true
    });
  }
};

export const init = (Alpine) => {
  Alpine.data('calendar', component);
};

export const component = (filterOptions) => ({
  filterOptions,
  page: 0,
  nextPage() {
    ++this.page;
  },
  layout: 'grid',
  isLoading: true,
  get today() {
    return new Date(new Date().setHours(0, 0, 0, 0));
  },
  get tomorrow() {
    return new Date(new Date(this.today).setDate(this.today.getDate() + 1));
  },
  _count: 0,
  _total: 0,
  events: new Map(),
  metadata: new Map(),
  externalEvents: new Map(),
  activeFilters: new Set(),
  filterValues: new Map(),
  pages: new Set(),
  dates: new Map(),
  showPrivate: false,
  maxDate: undefined,
  filteredEvents: null,
  startDate: new Date(),
  filtersOpen: false,
  mq: window.matchMedia('(min-width: 1024px)'),
  isToggleable: false,
  breakpoint() {
    return this.mq.matches;
  },
  init() {
    let related = [];

    const urlParams = (() => {
      const params = new URLSearchParams(location.search);

      if (params.has('showPrivate')) this.showPrivate = true;

      if (params.has('start')) {
        let start = new Date(params.get('start').replaceAll('-', '/'));
        if (!isNaN(start)) this.startDate = start;
      }

      if (params.has('related')) {
        related = [...params.getAll('related')];
      }

      return [
        params.getAll('audience'),
        params.getAll('accessibility'),
        params.getAll('category'),
        params.getAll('venue'),
        params.getAll('start'),
        params.getAll('related')
      ].flat();
    })();

    const eventSetup = (event) => {
      event._start = event.start && new Date(event.start);
      event._end = event.end && new Date(event.end);

      event.metadata = event.metadata.reduce(
        (eventMetadata, val) => eventMetadata.add(this.metadata.get(val)),
        new Set()
      );

      this.events.set(event._id, event);
      event = this.events.get(event._id);

      event.resolved = false;
      event.resolve = resolveEvent.bind(event);

      event.metadata.forEach((metadata) =>
        metadata.events.add(this.events.get(event._id))
      );
    };

    Alpine.effect(() => {
      const metadata = new Set(this.metadata.values());

      const grouped = Array.from(metadata).reduce((map, metadata) => {
        if (!map.has(metadata._type)) map.set(metadata._type, new Set());

        map.get(metadata._type).add(metadata);

        return map;
      }, new Map());

      this.filterValues = new Map([
        ['Event Type', grouped.get('metadata.event.category')],
        ['Audience', grouped.get('metadata.audience')],
        ['Location', grouped.get('venue')],
        ['Accessibility', grouped.get('metadata.accessibilityFeature')],
        ['Related', grouped.get('related')]
      ]);
    });

    const andTypes = ['metadata.accessibilityFeature'];

    Alpine.effect(() => {
      const events = new Set(this.events.values()),
        filterTypes = new Map(),
        dates = new Map();

      this.activeFilters.forEach((metadata) => {
        let key = andTypes.includes(metadata._type)
          ? metadata._id
          : metadata._type;

        if (!filterTypes.has(key)) filterTypes.set(key, new Set());

        metadata.events.forEach((event) => filterTypes.get(key).add(event));
      });

      events.forEach((event) => {
        event.metadata.forEach(({ visible }) => {
          if (!visible && !this.showPrivate) events.delete(event);
        });

        filterTypes.forEach((type) => {
          if (!type.has(event)) events.delete(event);
        });
      });

      events.forEach((event) => {
        let date = new Date(
          event._start > this.startDate ? event._start : this.startDate
        );

        let end = new Date(event._end || event._start);

        date.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        while (date <= end) {
          if (!dates.has(date.getTime())) {
            dates.set(date.getTime(), {
              date: new Date(date),
              events: new Set()
            });
          }

          dates.get(date.getTime()).events.add(event);
          date.setDate(date.getDate() + 1);
        }
      });

      this.filteredEvents = events;
      this.maxDate = Array.from(dates.values()).pop()?.date;

      this.page = 0;
      this.pages = Array.from(dates.values()).reduce(
        (pages, { date, events }, i) => {
          const key = Math.floor(i / 7);

          if (!pages[key])
            pages[key] = {
              dates: new Map(),
              events: []
            };

          pages[key].dates.set(date, events);
          pages[key].events.push(...Array.from(events));

          return pages;
        },
        []
      );
    });

    Alpine.effect(() => {
      let resolveEvents = [];

      this.pages[this.page]?.events.forEach((event) => {
        if (!event.resolved) resolveEvents.push(event);
      });

      this.view = this.pages.reduce((acc, { dates }, i) => {
        if (i <= this.page)
          dates.forEach((events, date) => (acc[date] = Array.from(events)));

        return acc;
      }, {});

      if (resolveEvents.length) {
        sanity
          .fetch(`*[_id in $ids]${projection}`, {
            ids: resolveEvents.map(({ _id }) => _id),
            today: this.today
          })
          .then((events) => {
            resolveEvents.forEach((event) => event.resolve(events));

            const resolvers = [];

            const externalEvents = resolveEvents.reduce(
              (events, { _id, externalEvent }) => {
                if (!externalEvent || !externalEvent.id) return events;

                const event = this.events.get(_id);
                const { type, id } = event.externalEvent;

                event.ticketUrl = ticketUrl(event);

                if (!events[type]) events[type] = [];

                if (!this.externalEvents.has(id)) {
                  let resolver,
                    promise = new Promise((resolve) => {
                      resolver = resolve;
                    })
                      .then((events) => events.find((event) => event.id == id))
                      .then((response) => ({
                        ...response,
                        ...externalEvent
                      }))
                      .then((response) => ({
                        ...response,
                        tickets:
                          response?.ticket_types ||
                          response?.ticket_classes?.map(
                            ({ cost, ...rest }) => ({
                              price: cost?.value / 100 || 0,
                              ...rest
                            })
                          ),
                        is_sold_out: response?.ticket_availability?.is_sold_out,
                        waitlist_available: response?.ticket_availability?.waitlist_available
                      }));

                  this.externalEvents.set(id, promise);
                  events[type].push(id);
                  resolvers.push(resolver);
                }

                event.externalEvent = Promise.resolve(this.externalEvents.get(id)).then((externalEvent) => {
                  Object.assign(event, {
                    tickets: externalEvent?.tickets ?? event.tickets,
                    is_free: externalEvent?.is_free ?? event.is_free,
                    is_sold_out: externalEvent?.is_sold_out ?? event.is_sold_out,
                    waitlist_available: externalEvent?.waitlist_available ?? event.waitlist_available
                  })

                  return externalEvent
                })

                return events;
              },
              {}
            );

            if (resolvers.length)
              fetch(
                new URL(
                  `/_/events/ticketing/?` + new URLSearchParams(externalEvents),
                  location.origin
                )
              )
                .then((r) => r.json())
                .then((events) =>
                  resolvers.forEach((resolve) => resolve(events))
                );
          });
      }
    });

    Alpine.effect(() => {
      const params = {
        date: this.startDate,
        start: this._count,
        limit: this._count + 200
      };

      if (this._count === 0 || this._total > this._count)
        sanity
          .fetch(
            `{
              "all": *[
                  _type == "event"
                  && !(type in ['exhibition','exhibition-touring','cinema-program'])
                  && (type != "_instance" || (type == "_instance" && defined(parent->)))
                  && (defined(end) && dateTime(end) > dateTime($date) || dateTime(start) >= dateTime($date))
                ]|order(start asc, coalesce(priority, 0) desc, end asc, title asc)
              }{
                "_total": count(all),
                "result": all[$start...$limit]{
                ...parent->,
                ...,
                "type": coalesce(parent->type, type)
              }{
                _id,
                start,
                end,
                type,
                "metadata": [
                  ...audiences[]->_id,
                  ...categories[]->_id,
                  ...accessibility[]->_id,
                  ...venues[]->{ "_id": coalesce(parent->parent->_id, parent->_id, _id) }._id,
                ][@ != null]
                + select(type == "cinema-screening" => ["e99c78cb-04c6-46ed-a5bf-af0d54c480e5"], [])
                + select(is_online => ["online"], [])
              }
            }{
              ...,
              "_count": count(result),
              "metadata": *[_id in ^.result[].metadata[]]{
                _id,
                _type,
                private,
                "name": coalesce(name, title),
              } + [{ "_id": "online", "_type": "venue", "name": "Online" }]
            }`,
            params
          )
          .then((response) => {
            if (related.length) {
              return sanity
                .fetch(
                  `{
                    "metadata": *[_id in $related[]._id]{
                      _id,
                      "_type": "related",
                      "key": "_id",
                      "name": coalesce(title, parent->title),
                      ...{
                        "events": *[_type == "event" && references(^._id, ^.parent._ref)]
                      }{
                        "events": *[_type == "event" && (_id in ^.events[]._id || references(^.events[]._id))]
                      }{
                        events[_id in $all[]._id || parent->id in $all[]._id]
                      }
                    }
                  }{
                    ...,
                    "result": $all[]{
                      ...,
                      "metadata": ^.metadata[^._id in events[]._id + [_id]]._id
                    }[count(metadata) > 0]
                  }`,
                  {
                    all: response.result.map(({ _id }) => ({ _id })),
                    related: related.map((_id) => ({ _id }))
                  }
                )
                .then(({ result, metadata }) => {
                  response.metadata.unshift(...metadata);

                  result.forEach(({ _id, metadata }) => {
                    response.result
                      .find((event) => event._id == _id)
                      ?.metadata.push(...metadata);
                  });

                  return response;
                });
            }

            return response;
          })
          .then(({ result, metadata, _count, _total }) => {
            metadata.forEach((metadata) => {
              if (!this.metadata.has(metadata._id)) {
                const inUrl = urlParams.some(
                  (param) =>
                    param == metadata._id ||
                    param.toLowerCase() == metadata.name.toLowerCase()
                );

                this.metadata.set(
                  metadata._id,
                  Object.assign(metadata, {
                    events: new Set(),
                    visible: !metadata.private || (metadata.private && inUrl)
                  })
                );

                if (inUrl) this.activeFilters.add(metadata);
              }
            });

            result.forEach(
              (event) => !this.events.has(event._id) && eventSetup(event)
            );

            this.isLoading = false;
            this._count = this._count + _count;
            this._total = _total;
          });
    });

    Alpine.effect(() => {
      const params = Array.from(this.activeFilters).map(
        ({ _type, key = 'name', ...rest }) => [
          _type.split('.').pop().replace('Feature', ''),
          rest[key]
        ]
      );

      if (
        this.startDate.setHours(0, 0, 0, 0) != new Date().setHours(0, 0, 0, 0)
      )
        params.push([
          'start',
          this.startDate
            .toLocaleDateString()
            .replace(/(\d{2}).(\d{2}).(\d{4})/, '$3-$2-$1')
        ]);

      history.replaceState(
        null,
        '',
        new URL(
          params.length ? `?` + new URLSearchParams(params) : ``,
          location.origin + location.pathname
        )
      );
    });
  },
  card() {
    return {
      ['x-data']() {
        return {
          html: ``,
          init() {
            Alpine.effect(async () => {
              this.event.externalEvent = await Promise.resolve(
                this.event.externalEvent
              );

              this.html = card({
                ...this.event,
                showDate: false,
                showDay: false,
                wrap: false,
                icons
              });
            });
          }
        };
      },
      ['x-html']: `html`
    };
  },
  dateFilter: {
    picker: null,
    ['x-data']() {
      return {
        show: false,
        init() {
          this.$el.insertAdjacentHTML(
            'afterbegin',
            `<button x-bind="toggle">
              Date
              <span :style="show && { transform: 'rotate(-180deg)' }">
                ${Icon('chevron-down', false, icons)}
              </span>
            </button>`
          );
        },
        fieldset: {
          ['x-init']() {
            this.$el.insertAdjacentHTML(
              'afterbegin',
              ['Today', 'Tomorrow']
                .map(
                  (label) =>
                    `<button x-bind="quickSelect('${label}')"></button>`
                )
                .join('')
            );
          },
          ['x-show']: 'show',
          ['@click.outside']: 'show = false'
        },
        toggle: {
          ['type']: 'button',
          ['@click']: 'show = !show'
        },
        quickSelect(label) {
          return {
            ['type']: 'button',
            ['x-text']: `'${label}'`,
            ['x-data']() {
              return {
                value: label == 'Tomorrow' ? this.tomorrow : this.today
              };
            },
            ['@click']() {
              this.startDate = this.value;
              this.picker.setDate(this.startDate);
            },
            [':class']() {
              return (
                this.startDate.valueOf() == this.value.valueOf() && 'selected'
              );
            }
          };
        },
        start() {
          return {
            ['x-init']() {
              const onSelect = (e) => {
                this.startDate = e.detail.date;
                this.open = false;
              };

              const onView = (e) => {
                const { view, date, target } = e.detail;

                if (view === `CalendarDay` && date < this.today)
                  target.classList.add('past');
              };

              const dateFilter = (date) => date > this.maxDate;

              this.picker = new easepick.create({
                date: this.startDate,
                element: this.$el,
                css: [calendarStyles],
                inline: true,
                locale: {
                  previousMonth: Icon(
                    'chevron-left',
                    'width="24" height="24"',
                    icons
                  ),
                  nextMonth: Icon('chevron', 'width="24" height="24"', icons)
                },
                setup(picker) {
                  picker.on('select', onSelect);
                  picker.on('view', onView);
                },
                plugins: [LockPlugin],
                LockPlugin: {
                  minDate: new Date(),
                  filter: dateFilter
                }
              });

              Alpine.effect(() => {
                this.maxDate &&
                  (this.picker.PluginManager.reloadInstance('LockPlugin'),
                  this.picker.renderAll());
              });
            }
          };
        }
      };
    }
  }
});

export default init;
