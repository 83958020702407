const { toHTML } = require('../../../src/utils/sanity/index.js');
const { imageHtml } = require('../_partials/image.js');
const { formatDays, formatTime } = require('../../utils/dates.js');
const { grid } = require('../_partials/grid.js');
const Icon = require('../../utils/icon.js');

module.exports = async function ({
  type,
  venueType,
  venues,
  show = {},
  blockHelpers,
  ...data
}) {
  const _if = (condition, template) =>
    (condition &&
      (typeof template == 'function' ? template(condition) : template)) ||
    ``;

  const functionSpaceHtml = ({
    _id,
    title,
    images,
    floorArea,
    capacity,
    description,
    floorPlan,
    parent
  }) => {
    return `<article ${blockHelpers.id({ _id })} class="card">
      ${_if(images?.[0], (image) =>
        imageHtml({ ...image, width: 580, height: 580 })
      )}

      <div>
        <h1 class="heading-lg">${title}</h1>
    
        ${_if(
          [
            {
              title: 'Building',
              value:
                parent?.title &&
                parent.title
                  .split(/\s/)
                  .reduce(
                    (acronym, word) => acronym + word.charAt(0).toUpperCase(),
                    ''
                  ),
              icon: Icon('mapPin')
            },

            ...(capacity
              ? Object.entries(capacity).map(([type, value]) => ({
                  title: `${
                    type.charAt(0).toUpperCase() + type.slice(1)
                  } Capacity`,
                  value,
                  icon: Icon(
                    (type == 'standing' && 'cocktail') ||
                      (type == 'seated' && 'sitting') ||
                      type
                  )
                }))
              : []),
            {
              title: 'Floor Area',
              value: floorArea && `${floorArea}m²`,
              icon: Icon('tapeMeasure')
            }
          ],
          (values) =>
            `<ul class="venue-details">${values.reduce(
              (acc, { title, value, icon }) =>
                acc +
                (value
                  ? `<li title="${title}">${icon}<span>${title}:</span> ${value}</li>`
                  : ``),
              ''
            )}</ul>`
        )}
    
        <main>
          ${toHTML(description, data)}
        </main>
      </div>
  
      <footer>
        <a class="card__cta" href="https://apac-api.priava.com/api/enquiry_form_QAGOMA.html" target="_blank">
          Enquire ${Icon('arrow-right')}
        </a>
  
        ${
          images?.length > 1
            ? `<div class="card__cta" x-data="carouselPopup" x-cloak>
  
            <button x-bind="trigger">
              Image Gallery ${Icon('arrow-right')}
            </button>
            
            <template x-teleport="body">
              <template x-bind="template">
                <div x-bind="wrapper">
                  <div x-data="carousel" x-bind="root">
                    <ul x-bind="viewport">
                      ${images
                        .map(
                          (image) => `<li x-bind="slide">
                        ${imageHtml({
                          ...image,
                          width: 1280,
                          height: 720,
                          fit: 'clip'
                        })}
                      </li>`
                        )
                        .join(``)}
                    </ul>
  
                    <template x-if="carousel">
                      <button x-bind="dirButton('prev')"></button>
                    </template>
  
                    <template x-if="carousel">
                      <button x-bind="dirButton('next')"></button>
                    </template>
                  </div>
                </div>
              </template>
            </template>
          </div>`
            : ``
        }
  
        ${_if(
          floorPlan?.url,
          (url) =>
            `<a class="card__cta" href="${url}" target="_blank">
              Floorplan ${Icon('download')}
            </a>`
        )}
      </footer>
    </article>`;
  };

  const venueHtml = ({
    _id,
    title,
    images,
    hours,
    description,
    contactInformation,
    bookingUrl,
    menus,
    address,
    google_place_id,
    links = []
  }) => {
    if (show.directionsLink)
      links.push({
        url: `https://www.google.com/maps/dir/?${new URLSearchParams({
          api: 1,
          destination: [title, address].join(),
          ...(google_place_id && { destination_place_id: google_place_id })
        })}`,
        text: 'Get Directions on Google Maps',
        icon: Icon('car')
      });

    if (show.mapsLink)
      links.push({
        url: '/visit/gallery-maps/',
        text: 'View Gallery Maps',
        icon: Icon('map')
      });

    return `<article ${blockHelpers.id({
      _id
    })} class="${blockHelpers.oddOrEven('imageBlock')}">
  
    ${_if(images?.[0], (image) =>
      imageHtml({ ...image, width: 885, height: 758 })
    )}
    
    <div>
      <div>
        <h1 class="heading-lg">${title}</h1>
      
        <main class="accent">
          ${_if(description, `<div>${toHTML(description, data)}</div>`)}
          ${_if(hours, hoursHtml)}
    
          ${_if(
            show.contactInformation && contactInformation,
            ({ title, ...contactInformation }) =>
              toHTML(contactInformation, data)
          )}

          ${_if(
            [menus, links].some((arr) => arr?.length),
            `<section class="venue-links">
              ${_if(
                menus?.length,
                toHTML(
                  {
                    _type: 'documentList',
                    layout: 'horizontal',
                    documents: menus?.map(
                      ({ label: displayName, file, ...menu }) => ({
                        ...menu,
                        ...file,
                        displayName:
                          menus.length === 1 && displayName.match(/^menu$/i)
                            ? `${title} ${displayName}`
                            : displayName
                      })
                    )
                  },
                  data
                )
              )}

              ${_if(
                links.length,
                `<ul class="document-list">${links
                  .map(
                    ({ url, text, icon }) =>
                      `<li>
                        <a href="${url}" target="_blank">
                          ${[icon, text, Icon('arrow-right')].join(' ')}
                        </a>
                      </li>`
                  )
                  .join('\n')}
                </ul>`
              )}
            </section>`
          )}
        </main>

        ${_if(
          bookingUrl,
          `<a href="${bookingUrl}" class="button">
              Make a booking ${Icon('chevron')}
            </a>`
        )}
      </div>
    </div>
  </article>`;
  };

  const hoursHtml = (hours) => `<section class="operating-hours">
    ${Icon('clock', 'role="presentation" style="width:1em"')}
  
    ${hours.reduce((html, { days, open, close }) => {
      let times = open || close;

      return `${html}<div>
        ${_if(days, `<strong>${formatDays(days)}${times ? `:` : ``}</strong>`)}
        ${_if(times, formatTime(open, close))}
      </div>`;
    }, '')}</section>`;

  if (type == 'venueType' && venueType == 'functionSpace') {
    return `<section class="block-venueShowcase" ${blockHelpers.id(data)}>
      ${grid({
        title: 'Our Venues',
        columns: 3,
        items: venues.map(functionSpaceHtml)
      })}
    </section>`;
  } else {
    return `<section class="mosaic block-venueShowcase" ${blockHelpers.id(
      data
    )}>
    ${
      venues &&
      venues
        .map((venue) =>
          venueHtml(venue, {
            directionsLink: false,
            mapsLink: false,
            contactInformation: true,
            ...show
          })
        )
        .join('')
    }
    </section>`;
  }
};
