﻿const {
  toHTML,
  imageUrlBuilder
} = require('../../../src/utils/sanity/index.js');

const { imageHtml } = require('../_partials/image.js');

const config = {
  primary: { align: 'start', draggable: false },
  thumbnails: {
    axis: 'y',
    align: 'start',
    dragFree: true
  }
};

module.exports = function ({ title, items, blockHelpers, ...data }) {
  return `<section class="block-themesShowcase" ${blockHelpers.id(data)}>
    <header>
      <h1 class="heading-xl">${title}</h1>
    </header>

    <div x-id="['carousel']" x-data='carousel(${JSON.stringify(
      config.primary
    )})' class="block-themesShowcase__wrapper">

      <div class="block-themesShowcase__thumbnails" x-data='synchronizedCarousel(${JSON.stringify(
        config.thumbnails
      )})' x-bind="root">
        <div x-bind="viewport" :id="$id('carousel', 'thumbnails')">
          <div x-show="false" x-cloak x-effect="carousel && $el.remove()">Placeholder</div>
        </div>
      </div>

      <template x-if="carousel">
        <div x-bind="buttons">
          <template x-if="carousel.slideNodes()[currentSlideIndex]">
            <h1 class="block-themesShowcase__item__title">
              <span x-text="(currentSlideIndex+1) + '. '"></span>
              <span x-text="carousel.slideNodes()[currentSlideIndex].querySelector('.block-themesShowcase__item__title').textContent">
            </h1>
          </template>
          <button x-bind="dirButton('prev')"></button>
          <button x-bind="dirButton('next')"></button>
        </div>
      </template>

      <div class="block-themesShowcase__content" x-bind="root" x-data="{
        gutter: 0,
        thumbnailTemplate() {
          return {
            ['x-teleport']: \`#\` + $id('carousel', 'thumbnails')
          };
        }
      }" :style="{ '--gutter': gutter }">
        <div x-bind="viewport">
          ${items
            .map(
              ({ _key, title, images, content }) => `
          <section ${blockHelpers.id({
            _key
          })} class="block-themesShowcase__item"
            x-init="gutter = $el.offsetWidth - $el.scrollWidth + 'px'"
            x-data="{ slideIndex: Array.prototype.indexOf.call($el.parentNode.children, $el) }">

            <template x-bind="thumbnailTemplate">
              <button @click="carousel.scrollTo(slideIndex)" :aria-controls="$id('carousel')" aria-label="\`Go to $\{$el.textContent.trim()}\`">
                ${((thumbnail) =>
                  thumbnail && `<img src="${thumbnail}" alt="${title}">`)(
                  thumbnail(images)
                )}
                ${title}
              </button>
            </template>

            <div class="block-themesShowcase__inner">
              <h1 class="block-themesShowcase__item__title"><span class="spacer"></span> ${title}</h1>

              ${imageCarousel(images)}

              <div>${toHTML(content)}</div>
            </div>
          </section>
          `
            )
            .join(``)}
        </div>
      </div>
    </div>
  </section>`;
};

const thumbnail = ([image] = []) =>
  image ? imageUrlBuilder(image).width(240).height(150).url() : ``;

const imageCarousel = (images) => {
  if (!images) return ``;

  return `<div class="block-themesShowcase__images" x-data="carousel()" x-bind="root">
    <ul x-bind="viewport">
      ${images
        .map(
          (image) =>
            `<li x-bind="slide">${
              image && imageHtml({ ...image, height: 540 })
            }</li>`
        )
        .join('\r\n')}
    </ul>
  </div>`;
};
