﻿const striptags = require('striptags');

const defaults = {
  nonTextBehavior: true
};

module.exports = function blocksToText(blocks, opts = {}) {
  const options = Object.assign({}, defaults, opts);
  return striptags(
    blocks
      .map((block) => {
        if (block._type !== 'block' || !block.children) {
          return options.nonTextBehavior === 'remove'
            ? ''
            : `[${block._type} block]`;
        }

        return block.children.map((child) => child.text).join('');
      })
      .join('\n\n')
  );
};
