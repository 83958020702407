const { toHTML } = require('../../../src/utils/sanity/index.js');

module.exports = function ({ title, dates, blockHelpers, ...data }) {
  if (!dates) return ``;

  return `<section class="block-timeline" ${blockHelpers.id(data)}>
    <div class="container">
      <h1 class="heading-lg">${title}</h1>
      <ol>
        ${dates.reduce(
          (dates, { year, text }) => `${dates}
          <li>
            <div>
              <h1>${year}</h1>
              <div>${toHTML(text, data)}</div>
            </div>
          </li>`,
          ''
        )}
      </ol>
    </div>
  </section>`;
};
