const { client: sanityClient } = require('../../utils/sanity/index.js');
const { portableTextProjection, venuesProjection, imageProjection } = require('../../$content/_common.js');
import { card } from '../../_includes/_partials/card.js';
const { eventUrl } = require('../../$content/events');
const { formatVenues } = require('../../utils/venues.js');
const slugify = require('@sindresorhus/slugify');
const arrowRight = new URL(`/assets/svgs/arrow-right.svg`, import.meta.url);

// Strips character encoding and whitespace
const normaliseString = (str) => { return str ? str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ""; }

const sanity = sanityClient.config({
  useCdn: true,
  withCredentials: true,
  token: false
});

const query = `*[_type == "event" && _id == $id][0]{
  "exhibition": @,
  ...{
    ...,
    objects[]{
      ...,
      ...reference->
    }{
      ...,
      defined(image) => { ${imageProjection} },
      ${venuesProjection}
    }
  }{
    "creators": *[_id in ^.objects[].creators[]._ref]{
      ...,
      "name": coalesce(givenName +" "+ familyName, name),
      "sortname": array::join(array::compact([familyName, givenName, name]), " "),
      defined(image) => { ${imageProjection} },
      count(content) > 0 => { content[]${portableTextProjection} },            
      "objects": ^.objects[^._id in creators[]._ref]
    }
  }
}`;

export const init = (Alpine) => {
  Alpine.data('exhibitionResourceFeed', component);
};

export const component = (id) => ({
  url: '',
  creators: [],

  // Search and filter functionality
  svenue: 'qagoma',
  sterm: '',
  smore: false,
  get filteredCreators() {
    if (this.creators.length <= 0) return [];
    let data = [...this.creators];
    
    // Filter by gallery building
    if (this.svenue != 'qagoma') {
      const searchVenue = this.svenue=='qag' ? "Queensland Art Gallery" : "Gallery of Modern Art";
      data = data.filter(c =>
        c.objects?.some(obj =>
          obj.venues?.some(venue => venue.type === 'galleryBuilding' && venue.title === searchVenue)
        )
      );
    }

    // Search for artist by name
    if (this.sterm != '') {
      const searchTerm = normaliseString(this.sterm.trim());
      data = data.filter(
        c => normaliseString(c.name)?.includes(searchTerm) || 
          normaliseString(c.givenName)?.includes(searchTerm) || 
          normaliseString(c.familyName)?.includes(searchTerm)
      );
    }

    // Limits results to 10, randomised, unless 'show more' has been clicked
    if (!this.smore && data.length > 10) {
      data = data.filter(c => c.showInListing);
      const shuffled = [...data].sort(() => Math.random() - 0.5);
      data = shuffled.slice(0, 10);
    }

    // Sort by name
    data.sort((a, b) => a.sortname.localeCompare(b.sortname));

    return data;
  },

  async init() {
    this.creators = await sanity
      .fetch(query, { id })
      .then(({ creators, exhibition }) => {
        if (creators) {
          this.url = eventUrl(exhibition);
          
          // Filter the artists to remove duplicates
          let uniqueCreators = new Set();
          creators = creators.filter(creator => 
            uniqueCreators.hasOwnProperty(creator._id) ? false : (uniqueCreators[creator._id] = true)
          );
          
          // Pre-process card data
          creators.forEach(creator => {
            if (!creator.slug) creator.slug = slugify(creator.name);            
            creator.url = `${this.url}/${creator.slug}`;
            if (!creator.sortname) creator.sortname = `${creator.familyName || ''}${creator.givenName || ''}` || creator.name;
            creator.showInListing = !creator.objects.some(o => o.showInListing==false);
            
            // Format the venue text, but get unique venues first
            const venues = [
              ...new Map(
                creator.objects.flatMap(art => art.venues)
                .map(venue => [(venue => venue?._id)(venue), {...venue, expandedTitle:''}])
              ).values()
            ];
            creator.pretitle = formatVenues(venues, true)
              .replace(/Queensland Art Gallery/g, 'QAG')
              .replace(/Gallery of Modern Art/g, 'GOMA')
              .replace(/^QAG, GOMA$/, 'QAGOMA');
          });

          console.log('exhibitionResourceFeed', {id, query, 'url':this.url, exhibition, creators});
          return creators;
        }
        return null;
      });

    this.$nextTick(() => {
      this.$dispatch('modal:register', `^${location.pathname}.+`)
    })
  },

  card(creator) {
    return {
      ['x-html']() {
        return card({
          ...creator,
          wrap: false,
          title: creator.name,
          image: creator.objects.find(art => art.image).image || creator.image || '',
          content: '',
          icons: { 'arrow-right': arrowRight }
        });
      }
    };
  },
});

export default init;