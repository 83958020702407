const Icon = require('../../utils/icon.js');
const { toHTML } = require('../../../src/utils/sanity/index.js');

module.exports = function ({
  title,
  subtitle,
  text,
  terms,
  image,
  signup_preset: preset,
  subscriptionId,
  allowAlreadySubscribed = false,
  blockHelpers,
  ...data
}) {
  return Promise.resolve(image).then(
    (image) => `<aside class="emailSignup"${
      image ? ` style="background-image:url('${image?.url}')"` : ``
    } ${blockHelpers.id(data)}>
    
    <header>
      <h1>${title}</h1>
      ${subtitle ? `<p>${subtitle}</p>` : ``}
      ${text ? toHTML(text, data) : ``}
    </header>
      
    <form action="/_/subscribe/" x-data="emailSignup()" x-bind="form">
      <div x-cloak x-show="message" class="emailSignup__message" :class="success && 'success'">
        <span x-html="message"></span>
      </div>

      <div class="emailSignup__fields">
        <div>
          <label for="fname">First Name</label>
          <input type="text" id="fname" name="fname" autocomplete="given-name" required>
        </div>

        <div>
          <label for="email">Email</label>
          <input type="email" id="email" name="email" required>
        </div>
      </div>

      ${Object.entries({ preset, subscriptionId, allowAlreadySubscribed })
        .map(([k, v]) =>
          v ? `<input type="hidden" name="${k}" value="${v}">` : ``
        )
        .join('')}

      <button class="button" type="submit">
        Sign Up ${Icon('chevron')}
      </button>
    </form>

    ${terms ? `<footer>${toHTML(terms, data)}</footer>` : ``}
  </aside>`
  );
};
