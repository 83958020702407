﻿import blocks from '/src/components/blocks/index.js';

import youtubeVideo from '/src/components/youtubeVideo.js';
import carousel from '/src/components/carousel.js';
import calendar from '/src/components/calendar.js';
import emailSignup from '/src/components/emailSignup.js';
import collapsible from '/src/components/collapsible.js';
import proportionalImageGroup from '/src/components/proportionalImageGroup.js';

export default blocks = (Alpine) => {
  Object.values(blocks).map(({ init }) => init(Alpine));
  youtubeVideo(Alpine);
  carousel(Alpine);
  calendar(Alpine);
  emailSignup(Alpine);
  collapsible(Alpine);
  proportionalImageGroup(Alpine);
};
