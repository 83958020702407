const Icon = require('../../utils/icon.js');

const { getImageDimensions } = require('@sanity/asset-utils');
const {
  imageUrlBuilder,
  toHTML,
  toText,
  client: sanity
} = require('../../../src/utils/sanity/index.js');

const resolveImages = (images) =>
  sanity
    .fetch(`*[_id in $images]`, { images })
    .then((data) =>
      images.map((image) => data.find(({ _id }) => _id == image))
    );

const imageHtml = (
  {
    width,
    height,
    fit = 'crop',
    quality = 90,
    metadata,
    classes,
    url,
    srcset = [],
    ...image
  },
  caption,
  fullSizeUrl
) => {
  if (!image) return;

  try {
    if (image._id || image._ref) {
      const builder = imageUrlBuilder(image);

      const {
        width: maxWidth,
        height: maxHeight,
        aspectRatio
      } = getImageDimensions(image);

      if (!fullSizeUrl) fullSizeUrl = builder.url();

      if (!width && height) width = Math.round(height * aspectRatio);
      if (width && !height) height = Math.round(width * aspectRatio);

      srcset = Array(10)
        .fill(320)
        .map((v, i) => v * (i + 1))
        .concat(width)
        .sort((a, b) => a - b)
        .reduce((srcset, w) => {
          let h = height && Math.round(w * (height / width));

          if (!(w > maxWidth && h > maxHeight))
            srcset.push([
              builder.size(w, h).fit(fit).quality(quality).url(),
              w
            ]);

          return srcset;
        }, []);

      url = srcset?.shift()?.[0];
    }

    if (!url) throw new Error('No url resolved for image');

    caption = caption || image?.caption;

    return `<figure${classes ? ` class="${classes}"` : ``}${metadata?.palette?.muted ? ` style="--c-background: ${metadata.palette.muted.background}; --c-foreground: ${metadata.palette.muted.foreground};"` : ``}>
      <picture${metadata ? lqip(metadata) : ``}>
        ${img({ url, srcset, width, height, caption })}
      </picture>
      
      ${caption ? captionHtml(caption, fullSizeUrl) : ``}
    </figure>`;
  } catch (e) {
    console.log(e);
    return ``;
  }
};

const img = ({ url, srcset, width, height, caption }) =>
  `<img src="${url}" width="${width}" height="${height}" style="--width:${width}px; --height:${height}px;" loading="lazy"${srcsetHtml(
    srcset
  )} alt="${caption ? toText(caption) : ``}" crossorigin="anonymous">`;

const lqip = ({ lqip }) =>
  lqip ? ` style="background:url('${lqip}') center / cover"` : ``;

const srcsetHtml = (srcset) =>
  srcset.length
    ? ` srcset="${srcset
        .map(([url, w]) => `${url.replaceAll(',', '%2C')} ${w}w`)
        .join(', ')}"`
    : ``;

const captionHtml = (caption, fullSizeUrl) => {
  caption = [...caption];

  try {
    if (fullSizeUrl) {
      let block = caption.at(-1);

      block.children = (block.children || []).concat(
        {
          _type: 'span',
          text: ' / '
        },
        {
          _type: 'span',
          marks: ['imageUrl'],
          text: 'View full image'
        }
      );

      block.markDefs = (block.markDefs || []).concat({
        _type: 'link',
        _key: 'imageUrl',
        href: fullSizeUrl,
        target: '_blank'
      });
    }

    return `<figcaption x-data="imageCaption()" :class="open && 'open'">
      <button x-cloak @click="open = !open">
        <span class="sr-only">Toggle Caption</span>
        ${Icon('image-caption')}
      </button>
      
      <div>${toHTML(caption)}</div>
    </figcaption>`;
  } catch (e) {
    console.log(e);
    return ``;
  }
};

module.exports = {
  resolveImages,
  imageHtml,
  captionHtml
};
