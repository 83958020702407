import DefaultField from './DefaultField';

const { SECUREPAY_CLIENT_ID, SECUREPAY_MERCHANT_CODE, SECUREPAY_API_ROOT } =
  process.env;

function loadSecurePayLibrary() {
  let script = document.getElementById('securepay-ui-js');

  if (!script) {
    script = document.createElement('script');
    script.id = 'securepay-ui-js';

    script.src = new URL(
      `v3/ui/client/securepay-ui.min.js`,
      SECUREPAY_API_ROOT
    );

    document.head.appendChild(script);
  }

  return new Promise((resolve) => {
    if (script.loaded) {
      resolve(script);
    } else {
      script.onload = resolve;
    }
  });
}

function submitPayment(params) {
  return fetch(
    new URL(
      '/.netlify/functions/securePayTransactionHandler?' +
        new URLSearchParams(params),
      location
    )
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.status == 'paid') {
        return response;
      } else {
        throw new Error(
          `There was an error processing your donation. Please check your payment details and try again. Contact the Foundation on +61 (0)7 3840 7262 or foundation@qagoma.qld.gov.au if this error persists (Error: ${
            response.gatewayResponseMessage ??
            response.error.name ??
            response.error.message
          })`,
          {
            cause: response
          }
        );
      }
    });
}

export default function SecurePayDonationField(props) {
  const { donationAmountField } = props,
    defaults = DefaultField(props);

  return {
    ...defaults,
    input: {
      ...defaults.input,
      ':required': `!token`,
      '@input': undefined
    },
    setCustomValidity: undefined,
    isFormValid: false,
    token: null,
    createdAt: null,
    loaded: false,
    donationAmountField,
    get donationAmount() {
      const value = this.values[this.donationAmountField];

      if (value === 'Custom') {
        return this.values['fields[0-1.custom_donation_amount_string]'];
      } else {
        return value;
      }
    },
    async init() {
      defaults.init();

      const securePayLibrary = loadSecurePayLibrary();

      function onFormValidityChange(isFormValid) {
        this.isFormValid = isFormValid;
      }

      function onTokeniseSuccess({ token, createdAt }) {
        this.token = token;
        this.createdAt = createdAt;
      }

      function onTokeniseError(error) {
        console.error(error);
      }

      function onLoadComplete(UI) {
        let inProgress = false,
          isValid = false;

        Alpine.effect(() => {
          if (this.isFormValid) {
            UI.tokenise();
          } else {
            this.token = null;
            this.createdAt = null;
          }
        });

        this.addSubmitHandler(
          function ({ resubmit }) {
            if (isValid) return true;

            if (!inProgress) {
              inProgress =
                new Promise((resolve, reject) => {
                  grecaptcha.ready(() => {
                    grecaptcha
                      .execute(this.recaptchaSiteKey, { action: 'checkout' })
                      .then(resolve)
                      .catch(reject);
                  })
                }).then((gr_token) =>
                  submitPayment({
                    ...Object.fromEntries(new FormData(this.$refs.form).entries()),
                    token: this.token,
                    createdAt: this.createdAt,
                    amount: this.donationAmount,
                    gr_token
                  })
                    .then(
                      ({ orderId }) => {
                        this.value = orderId;
                        isValid = true;

                        this.$nextTick(resubmit);
                      },
                      (error) => {
                        this.value = false;
                        isValid = false;

                        this.setCustomValidity(error.message);
                        this.showValidationMessage = true;
                      }
                  )
                )
                .finally(() => {
                  inProgress = false;
                  this.$nextTick(() => this.setCustomValidity(''));
                });
            }

            return inProgress;
          }.bind(this)
        );
      }

      await securePayLibrary;

      const UI = new securePayUI.init({
        containerId: this.$el.id,
        scriptId: 'securepay-ui-js',
        clientId: SECUREPAY_CLIENT_ID,
        merchantCode: SECUREPAY_MERCHANT_CODE,
        onLoadComplete: () => onLoadComplete.bind(this)(UI),
        card: {
          onTokeniseSuccess: onTokeniseSuccess.bind(this),
          onTokeniseError: onTokeniseError.bind(this),
          onFormValidityChange: onFormValidityChange.bind(this)
        },
        style: {
          backgroundColor: '#f2f2f4',
          label: {
            font: {
              family: 'Arial, Helvetica, sans-serif',
              size: '20px',
              color: '#3c3c3c'
            }
          },
          input: {
            font: {
              family: 'Arial, Helvetica, sans-serif',
              size: '24px',
              color: '#3c3c3c'
            }
          }
        }
      });
    }
  };
}
