﻿let parcelManifest;

try {
  parcelManifest = require('../../../../../dist/parcel-manifest.json');
} catch {}

module.exports = (
  name,
  attrs = `role="presentation"`,
  files = parcelManifest,
  url
) => {
  try {
    let svgContent;

    if (url) {
      svgContent = `<defs>
        <rect id="rect" width="100" height="100" x="0" y="0"/>
      </defs>
      
      <mask id="${name}">
        <use href="#rect" fill="white"/>    
        <image width="100" height="100" x="0" y="0" href="${url}"/>
      </mask>
    
      <mask id="mask-${name}">
        <use href="#rect" fill="white"/>
        <use href="#rect" fill="black" mask="url(#${name})"/>
      </mask>
    
      <use href="#rect" mask="url(#mask-${name})"/>`;
    } else {
      let fileName = `/assets/svgs/${name}.svg`;

      if (files) fileName = files[`../src${fileName}`] || files[name];

      if (process.env.ELEVENTY_SERVERLESS && !fileName)
        throw new Error('Could not find icon');

      svgContent = `<use href="${fileName}#path"/>`;
    }

    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"${
      attrs ? ` ${attrs}` : ``
    }>
      ${svgContent}
    </svg>`;
  } catch (e) {
    console.error(e);
    return ``;
  }
};
