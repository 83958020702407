import DefaultField from './DefaultField';

export default function CurrencyField(props) {
  const defaults = DefaultField(props);

  delete defaults.wrapper['x-model'];
  delete defaults.input['x-model'];

  if (props.options) {
    return {
      ...defaults,
      custom: undefined,
      init() {
        defaults.init();

        const options = props.options;
        const Fieldset = document.createElement('fieldset'),
          div = document.createElement('div');

        this.$nextTick(() => {
          this.value = 'custom';

          options.concat({}).forEach(({ label = '', value = 'custom' }, i) => {
            const Wrapper = document.createElement('div'),
              Input = document.createElement('input'),
              Label = document.createElement('label');

            Input.value = value;
            Input.type = 'radio';
            Input.name = this.$refs.input.name;
            Input.id = [this.$refs.input.id, Input.value].join('_');
            Input.setAttribute('x-model.number', 'value');

            Label.textContent = label;
            Label.setAttribute('for', Input.id);

            Wrapper.classList.add('form__input', 'form__input--radio-button');
            Wrapper.append(Input, Label);

            div.append(Wrapper);
          });

          Fieldset.classList.add('form__group', 'form__group--radio-button');
          Fieldset.append(div);

          this.$refs.input.insertAdjacentElement('beforeBegin', Fieldset);

          const custom = document.getElementById(
            [this.$refs.input.id, 'custom'].join('_')
          );

          custom.labels[0].insertAdjacentHTML(
            'afterBegin',
            '<span class="sr-only">Custom Donation Amount: </span>$'
          );

          custom.labels[0].insertAdjacentElement('beforeEnd', this.$refs.input);

          custom.setAttribute('x-ref', 'custom');
          custom.setAttribute('x-on:focus', '$refs.input.focus()');

          this.$refs.input.labels[0].classList.add('sr-only');
          this.$refs.input.removeAttribute('name');

          this.$watch('custom', () => {
            this.$root._x_model.set(this.custom);
            this.$refs.custom.value = this.custom;
            this.$refs.custom._x_model.set(this.$root._x_model.get());
          });
        });
      },
      wrapper: {
        ...defaults.wrapper,
        'x-model.number': 'values[inputName]'
      },
      input: {
        ...defaults.input,
        'x-model.number': 'custom',
        ':required': '!value',
        '@input': '$el.style.width = `${$el.value.length}ch`',
        '@focus':
          '$refs.custom._x_model.set(custom), $root._x_model.set(custom)',
        'x-init'() {
          defaults.input['x-init'].bind(this)();

          this.$el.setAttribute(
            ':min',
            `custom == $root._x_model.get() ? ${this.$el.min ?? 0.01} : ''`
          );

          Alpine.effect(() => {
            this.showValidationMessage =
              this.custom == this.$root._x_model.get();
          });
        }
      }
    };
  } else {
    return defaults;
  }
}
