export const init = (Alpine) => {
  Alpine.data('emailSignup', component);
};

export default init;

export const component = () => ({
  success: false,
  loading: false,
  message: null,
  onSubmit(e) {
    this.loading = true;

    sendData(new FormData(e.target))
      .then((response) => {
        console.log(response);
        this.success = true;
        this.message = `Thanks for subscribing ${response.email_address}`;
      })
      .catch((e) => {
        console.error(e);
        this.message = `Something went wrong! Please try again. If this issue persists, please <a href="/contact-us/">contact us</a>.`;
        if (e.cause) {
          e.cause.then((response) => {
            console.error(response);
			if (response.error.includes('already subscribed')) {
			  this.message = `You are already subscribed. If this is not the case please <a href="/contact-us/">contact us</a>.`;
			}
          })
        }
        this.success = false;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  onInvalid(e) {
    console.log(e);
  },
  form: {
    ['@submit.prevent']: 'onSubmit',
    ['@invalid']: 'onInvalid'
  }
});

const sendData = (formData) =>
  fetch(
    new URL(`/_/subscribe?${new URLSearchParams(formData)}`, location.origin)
  ).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Something went wrong', {
        cause: response.json()
      });
    }
  });
