const { toHTML } = require('../../../src/utils/sanity/index.js');
const { imageHtml } = require('../_partials/image.js');

module.exports = function ({
  quote,
  attribution,
  image,
  blockHelpers,
  ...data
}) {
  return `<section class="block-pullQuote hero ${blockHelpers.oddOrEven(
    'imageBlock'
  )}" ${blockHelpers.id(data)}>
    ${image ? imageHtml({ ...image, width: 1920, height: 920 }) : ``}

    <div class="hero__wrapper">
      <div class="hero__content">
        <figure>
          <blockquote>${toHTML(quote, data)}</blockquote>
          <figcaption>${toHTML(attribution, data)}</figcaption>
        </figure>
      </div>
    </div>
  </section>`;
};
