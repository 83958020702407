const Icon = require('../../utils/icon.js');

const grid = ({ title, items, columns = 4, link }) => `${
  title
    ? `<header class="section-header">
    <h2 class="heading-lg">${title}</h2>
    ${
      link?.url
        ? `<a href="${link.url}" class="cta-see-more">
          ${link.text}
          ${Icon('arrow-right')}
        </a>`
        : ``
    }
  </header>`
    : ``
}

  <ul class="grid card-grid" style="--column-count:${columns}">${items.reduce(
  (items, item) => items + item,
  ''
)}</ul>`;

module.exports = { grid };
