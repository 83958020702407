﻿/* Framework used is https://github.com/add2cal/add-to-calendar-button */
import { atcb_action } from 'add-to-calendar-button';

export const init = (Alpine) => {
  Alpine.data('addToCalendar', component);
};

const decodeText = (t) => {
  if (!t) return t;
  try {
    // Performs additional encoding on % characters before decoding, to avoid errors
    return decodeURIComponent(t.replace(/%(?![\dA-F]+)/g, '%25'));
  } catch (e) {
    console.log(e);
  }
  return t;
};

export const component = (
  config
) => ({
  config: {
    options: ['Apple', 'Google', 'Outlook.com', 'iCal'],
    timeZone: "Australia/Brisbane",
    listStyle: "dropdown-static",
    trigger: "click",
    hideBackground: true,
    hideTextLabelButton: true,
    hideBackground: true,
    hideCheckmark: true,
    lightMode: "system",
    hideBranding: true,
    size: "5|5|6"
  },

  init() {
    const button = this.$el;
    // Mandatory config fields are name and startDate/dates
    if (button && config?.name && (config?.startDate || config?.dates)) {
      Object.assign(this.config, config);

      this.config.name = decodeText(this.config.name);
      if (this.config.location) this.config.location = decodeText(this.config.location);
      if (this.config.description) this.config.description = decodeText(this.config.description);

      button.addEventListener('click', () => atcb_action(this.config, button));
    }
  }
});

export default init;