import { default as youtubeAPI, playerSettings } from './youtube';
import getYouTubeID from 'get-youtube-id';

export const init = (Alpine) => {
  Alpine.data('youtubeVideo', component);
};

export const component = (id) => ({
  id,
  player: null,
  async init() {
    const youtube = await youtubeAPI();

    this.id = getYouTubeID(id);

    Alpine.effect(
      () =>
        (this.player = new youtube.Player(this.$el, {
          ...playerSettings,
          videoId: this.id
        }))
    );
  }
});

export default init;
