const { eventUrl } = require('../../$content/events');
const { toHTML } = require('../../../src/utils/sanity/index.js');
const { card } = require('../_partials/card.js');

module.exports = function ({ items, title, text, blockHelpers, ...data }) {
  if (!items) return;

  const resolveLink = ({ url, reference }) => {
    if (reference) {
      if (reference?._type === 'event') {
        url = eventUrl(reference);
      } else if (reference?._ref || reference?._id) {
        let page = data.links[reference?._ref || reference?._id];
        if (page) url = page;
      }
    }

    return url;
  };

  return `<section x-id="['carousel']" class="block-linkCarousel" ${blockHelpers.id(
    data
  )}>
    <header>
      <h1 class="heading-xl">${title}</h1>
      <div>${toHTML(text, data)}</div>
    </header>

    <div>
      <div x-data="carousel({ align: 'start' })" x-bind="root">
        <ul x-bind="viewport">
          ${items
            .map(({ image, caption, text, link }) => {
              let url = resolveLink(link);

              return `<li x-bind="slide" class="card">
                ${card({
                  title: `<a href="${url}">${link.text}</a>`,
                  image: {
                    ...image,
                    caption,
                    height: 540
                  },
                  url,
                  content: toHTML(text, data),
                  useCta: true,
                  wrap: false
                })}
              </li>`;
            })
            .join('')}
        </ul>

        <template x-if="carousel">
          <div x-bind="buttons">
            <button x-bind="dirButton('prev')"></button>
            <button x-bind="dirButton('next')"></button>
          </div>
        </template>
      </div>
    </div>
  </section>`;
};
