const Icon = require('../../utils/icon.js');
const { eventUrl } = require('../../$content/events');
const { imageHtml } = require('../_partials/image.js');

module.exports = function ({
  image,
  title,
  text,
  link: { url, reference, ...link },
  links,
  blockHelpers,
  ...data
}) {
  if (reference) {
    if (reference?._type === 'event') {
      url = eventUrl(reference);
    } else if (reference?._ref || reference?._id) {
      let page = links[reference?._ref || reference?._id];
      if (page) url = page;
    }
  }

  return `<section class="block-promoBanner hero ${blockHelpers.oddOrEven(
    'imageBlock'
  )}" ${blockHelpers.id(data)}>
    ${image ? imageHtml({ ...image, width: 1920, height: 920 }) : ``}

    <div class="hero__wrapper">
      <div class="hero__content">
        <h1>${title}</h1>

        ${text ? `<p>${text}</p>` : ``}

        <a class="button" href="${url}">
          ${link?.text || 'Find out more'}
          ${Icon('chevron')}
        </a>
      </div>
    </div>
  </section>`;
};
