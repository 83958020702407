﻿const Icon = require('../../utils/icon.js');

module.exports = function ({
  title,
  link,
  query,
  context,
  blockHelpers,
  ...data
}) {
  link = {
    text: `See all ${title}`,
    url: '/whats-on/events',
    ...link
  };

  return `<section x-data="eventFeed(\`${query}\`)" x-show="events.length" ${blockHelpers.id(
    data
  )}>
    ${
      title
        ? `<header class="section-header">
        <h2 class="heading-lg">${title}</h2>
        ${
          link?.url
            ? `<a href="${link.url}" class="cta-see-more">
              ${link.text} ${Icon('arrow-right')}
            </a>`
            : ``
        }
      </header>`
        : ``
    }
    
    <ul class="grid grid-4 card-grid">
      <template x-for="event in events" :key="event._id">
        <li${
          context ? ` x-init="event.context = '${context}'"` : ``
        } class="card" x-bind="card(event)"></li$>
      </template>
    </ul>
  </section>`;
};
