import {
  client as sanity,
  toHTML,
  imageUrlBuilder
} from '../utils/sanity/index.js';

import templates from '../_includes/blocks/*.11ty.js';
templates.default = ({ text, _type }) =>
  `<pre>
    <h1 class="heading-lg">Placeholder</h1>
    ${text || _type}
  </pre>`;

const page = require('../$content/pages.js');
const events = require('../$content/events.js');
const types = { page, events };

export const component = (id, type = 'page', doc, data = {}) => ({
  _data: data,
  toHTML,
  imageUrlBuilder,
  subtitle: false,
  blocks: [],
  introduction: null,
  ...doc,
  async init() {
    console.log(doc);
    sanity
      .config({ withCredentials: true })
      .listen(
        `*[_id in path("drafts."+$id) || _id == $id] | order(_updatedAt desc)[0]`,
        { id }
      )
      .subscribe(async ({ result }) => {
        Object.assign(
          this,
          await resolve(getReferences(await types[type]?.transform(result)))
        );
      });
  },
  blockItem: {
    ['x-data']() {
      return {
        ready: false,
        html: false,
        template: null,
        getHtml: async function () {
          return await this.template({
            ...(await resolve(
              getReferences(
                await resolve(getReferences(Object.assign({}, this.block)))
              )
            )),
            ...this._data
          });
        }
      };
    },
    ['x-init']() {
      this.$watch(
        'html',
        (value) =>
          value &&
          this.$nextTick(() => {
            this.$el.innerHTML = value;
            this.ready = true;
          })
      );

      Alpine.effect(
        () =>
          this.block._type &&
          (this.template =
            templates?.[this.block._type?.replace('block:', '')] ||
            templates.default)
      );

      Alpine.effect(
        () =>
          this.blocks &&
          this.template &&
          this.$nextTick(async () => {
            let html = await this.getHtml();

            if (this.html !== html) {
              this.ready = false;
              this.html = html;
            }
          })
      );
    },
    ['x-if']() {
      return this.ready && this.html;
    }
  }
});

export default component;

const documents = {};

const useReference = (id) => {
  if (!documents[id]) documents[id] = sanity.getDocument(id);
  return documents[id];
};

const getReferences = (obj) => {
  if (obj && typeof obj == 'object' && !(obj?._type == 'image')) {
    if (obj?._ref) {
      obj = useReference(obj._ref);
    } else if (Array.isArray(obj)) {
      obj = obj.map(getReferences);
    } else {
      Object.keys(obj).forEach((key) => {
        obj[key] = getReferences(obj[key]);
      });
    }
  }

  return obj;
};

async function resolve(value) {
  const resolved = await value;

  if (isPlainObject(resolved)) {
    const entries = Object.entries(resolved);
    const resolvedEntries = entries.map(async ([key, value]) => [
      key,
      await resolve(value)
    ]);

    return Object.fromEntries(await Promise.all(resolvedEntries));
  } else if (Array.isArray(resolved)) {
    return Promise.all(resolved.map(resolve));
  }

  return resolved;
}

function isPlainObject(value) {
  return (
    typeof value === 'object' && value !== null && value.constructor === Object
  );
}
