const Icon = require('../../utils/icon.js');

module.exports = function ({
  title,
  type,
  query,
  facetFilters,
  resourceIds,
  blockHelpers,
  ...data
}) {
  return `<section class="block-storiesShowcase" x-cloak x-show="resources.length > 0" x-data='storiesShowcase(${JSON.stringify(
    {
      query,
      resourceIds,
      facetFilters
    }
  )})' ${blockHelpers.id(data)}>

  <header class="section-header">
    <h1 class="heading-lg">${title}</h1>
    <a href="/stories" :href="url" class="cta-see-more">
      <span>See all</span>
      ${Icon('arrow-right')}
    </a>
  </header>

  <template x-if="resources.length">
    <ul class="grid grid-4 card-grid">
      <template x-for="item in resources" :key="item.objectID">
        <li x-data="resource(item)" x-bind="Card"></li>
      </template>
    </ul>
  </template>
</section>`;
};
