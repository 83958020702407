const slugify = require('@sindresorhus/slugify');
const { card: eventCard } = require('../_partials/events.js');
const { card } = require('../_partials/card.js');
const { grid } = require('../_partials/grid.js');

module.exports = ({
  _key,
  title,
  collections: { educationResource, educationResourceCollection },
  link,
  blockHelpers
}) => {
  const featuredResources = educationResource.filter(
    ({ data: { item } }) =>
      item.priority > 50 ||
      (item?.relatedExhibition?.end &&
        new Date(item.relatedExhibition.end) > new Date())
  );

  if (_key === 'featured') {
    return `<section ${blockHelpers.id({ _key })}>
      ${grid({
        title,
        link,
        items: featuredResources.map(({ data: { item }, url }) =>
          item.start
            ? eventCard({
                ...item,
                url,
                content: false,
                showAdmission: false
              })
            : card({ ...item, url, content: false })
        )
      })}
    </section>`;
  }

  return [
    {
      title: 'Featured Resources',
      items: featuredResources
    },
    ...educationResourceCollection.map(({ data: { item }, url }) =>
      Object.assign(item, {
        url,
        items: item.resources.slice(0, 4)
      })
    ),
    {
      title: 'All Resources',
      items: [...educationResource].sort(
        (
          {
            data: {
              item: { title: a }
            }
          },
          {
            data: {
              item: { title: b }
            }
          }
        ) => a.localeCompare(b, 'en-AU', { numeric: true })
      )
    }
  ]
    .map(({ items, url, ...item }) =>
      items?.length > 0
        ? `<section ${blockHelpers.id({
            _key: [_key, slugify(item.title)].join('_')
          })}>
          ${grid({
            ...item,
            url,
            link: {
              url,
              text: `See all ${item?.title?.replace(
                ' Resources',
                ''
              )} resources`
            },
            items: items.map(({ data: { item }, url }) =>
              item.start
                ? eventCard({
                    ...item,
                    url,
                    content: false,
                    showAdmission: false
                  })
                : card({ ...item, url, content: false })
            )
          })}
        </section>`
        : ``
    )
    .join('');
};
