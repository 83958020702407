const QAGOMA_DateTimeFormat = function ({
  showEndAsDuration = false,
  showDate = true,
  showTime = true,
  showDay = true,
  ...opts
} = {}) {
  const customiseDateTimeParts = (parts = []) =>
    parts
      .reduce(
        (parts, part, i, arr) => {
          const { type, value } = part;

          if (type === 'literal') {
            if (value === ' ' && arr[i + 1]?.type === 'dayPeriod') return parts;

            if (value === ', ' && arr[i - 1]?.type === 'weekday')
              part.value = ' ';

            if (value === ':') part.value = '.';
            if (value === '–') part.value = ' – ';

            if (value === ' – ' && arr[i + 1].type !== 'hour') {
              parts.push([part], []);

              return parts;
            }
          }

          if (type === 'month') {
            part.value = part.value.slice(0, 3);
          }

          parts[parts.length - 1].push(part);

          return parts;
        },
        [[]]
      )
      .flatMap((parts) => {
        let split = parts.findIndex(({ value }) => value === ', ');

        if (split > -1) {
          let sep = parts.splice(split, 1);
          let time = parts.splice(split);
          let date = parts;

          let parenthesis = time.findIndex(({ value }) => value === ' (');
          if (parenthesis > -1) date.push(...time.splice(parenthesis));

          return Array().concat(time, sep, date);
        }

        return parts;
      });

  const duration = (start, end) =>
    Math.floor(Math.abs(start - end) / 1000 / 60) + ` mins`;

  return new Proxy(
    new Intl.DateTimeFormat(
      'en-AU',
      Object.assign(
        {
          year: showDate ? 'numeric' : undefined,
          month: showDate ? 'short' : undefined,
          day: showDate ? 'numeric' : undefined,
          hour: showTime ? 'numeric' : undefined,
          minute: showTime ? 'numeric' : undefined,
          weekday: showDay ? 'short' : undefined,
          timeZone: 'Australia/Brisbane'
        },
        opts
      )
    ),
    {
      get(target, prop) {
        if (typeof target[prop] === 'function') {
          if (prop.match(/format(Range)?ToParts/)) {
            return this.formatToParts(prop).bind(target);
          }

          if (prop.match(/format(Range)?/)) {
            return this.format(prop);
          }

          return target[prop].bind(target);
        }

        return Reflect.get(...arguments);
      },
      formatToParts(prop) {
        return function () {
          return customiseDateTimeParts(
            showEndAsDuration
              ? this.formatRangeToParts(arguments[0], arguments[0]).concat([
                  { type: 'literal', value: ' (', source: 'shared' },
                  {
                    type: 'duration',
                    value: duration(...arguments),
                    source: 'shared'
                  },
                  { type: 'literal', value: ')', source: 'shared' }
                ])
              : this[prop](...arguments)
          );
        };
      },
      format(prop) {
        return function () {
          return this[`${prop}ToParts`](...arguments).reduce(
            (str, { value }) => (str += value),
            ''
          );
        };
      }
    }
  );
};

const formatYearRange = (start, end) =>
  new QAGOMA_DateTimeFormat({
    year: 'numeric',
    showDay: false,
    showTime: false,
    showDate: false
  }).formatRange(new Date(start), new Date(end));

const formatDays = (days) => {
  if (days.length >= 7) return 'Daily';

  const daysToDates = Array.from(Array(8).keys()).map(
    (i) => ((d = new Date()), d.setDate(d.getDate() - (d.getDay() - i)), d)
  );

  let ranges = days.reduce((days, day, i, arr) => {
    if (arr[i - 1] + 1 == day) days[days.length - 1][1] = day;
    else days.push([day]);
    return days;
  }, []);

  let [first, last] = [ranges[0], ranges[ranges.length - 1]];

  if (first.length === 1 && first[0] === 0 && last[last.length - 1] === 6) {
    ranges[last.length - 1][1] = 7;
    ranges.shift();
  }

  const formatter = new QAGOMA_DateTimeFormat({
    showDate: false,
    showTime: false,
    showDay: true
  });

  return ranges
    .map(([min, max = min]) =>
      formatter.formatRange(daysToDates[min], daysToDates[max])
    )
    .join(', ');
};

const formatTime = (start, end = start) => {
  try {
    [start, end] = [start, end].filter(Boolean).map((time) => {
      try {
        let date = new Date(Date.parse(time));

        if (isNaN(date)) {
          throw new Error('Invalid date');
        }

        return date;
      } catch (e) {
        let [, ...parts] = time.match(/(\d{1,2}):(\d{2})/),
          date = new Date();

        date.setHours(...parts, 0, 0);
        return date;
      }
    });

    return date(start, end, {
      showDate: false,
      showDay: false
    });
  } catch (e) {
    console.log(e);
    return [start, end]
      .filter(Boolean)
      .join(' – ')
      .replace(/(\d):(\d)/g, '$1.$2');
  }
};

const date = (...args) => {
  const opts = Object.assign(
    {
      showEndAsDuration: false,
      showDate: true,
      showTime: true,
      showDay: true
    },
    isNaN(new Date(args.at(-1))) ? args.pop() : {}
  );
  try {
    let dates = args
      .reduce((dates, date) => {
        if (!isNaN(Date.parse(date))) dates.push(new Date(date));

        return dates;
      }, [])
      .sort((a, b) => a - b);

    if (dates.length === -1) throw Error('No valid dates');

    if (args.length > 1 && dates.length <= 1) {
      if (opts.showPermanent === false) return ``;
      return `Permanent`;
    }

    if (dates.length > 1) dates = [dates[0], dates.at(-1)];

    let formatter = new QAGOMA_DateTimeFormat(opts);

    return dates.length > 1
      ? formatter.formatRange(...dates)
      : formatter.format(...dates);
  } catch (e) {
    console.log(e);
    return ``;
  }
};

module.exports = {
  QAGOMA_DateTimeFormat,
  formatYearRange,
  formatDays,
  formatTime,
  date
};
