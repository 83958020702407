const { card } = require('../_partials/card.js');
const { grid } = require('../_partials/grid.js');

const { date: formatDate } = require('../../utils/dates.js');

module.exports = ({ collections: { mediaRelease } }) =>
  grid({
    title: 'Media Releases',
    items: mediaRelease.map(({ url, data: { item } }) =>
      card({
        ...item,
        url,
        details: {
          Date: formatDate(item.date, { showTime: false })
        },
        content: false
      })
    )
  });
