import { date as formatDate } from '../../utils/dates';
import { card } from '../../_includes/_partials/card';

export const init = (Alpine) => {
  Alpine.data('blogShowcase', component);
};

const arrowRight = new URL(`/assets/svgs/arrow-right.svg`, import.meta.url);

export const component = (type, param) => ({
  type,
  param,
  posts: [],
  link: {
    text: 'QAGOMA Blog',
    url: 'https://blog.qagoma.qld.gov.au/'
  },
  init() {
    Alpine.effect(() => {
      this.taxonomy = this.type !== 'posts' && getTaxonomy(this.type);
    });

    Alpine.effect(() => {
      this.term = this.taxonomy.then(
        ({ rest_base: taxonomy_rest_base }) =>
          taxonomy_rest_base &&
          getTerm(this.param, taxonomy_rest_base).then((term) =>
            Object.assign(term, { taxonomy_rest_base })
          )
      );
    });

    Alpine.effect(async () => {
      let { name, link } = await this.term;

      Object.assign(this.link, {
        text: name || 'QAGOMA Blog',
        url: link || 'https://blog.qagoma.qld.gov.au/'
      });
    });

    Alpine.effect(async () => {
      const params = {};
      let { type, param } = this;

      try {
        if (type === 'posts' && param) {
          params.includes = param.map((id) => id.replace('post#', ''));
        } else {
          term = await this.term;
          params[term.taxonomy_rest_base] = term.id;
        }
      } finally {
        this.posts = await getPosts(params);
      }
    });
  },
  card() {
    return {
      ['x-html']() {
        const { author, date, link: url, image, title } = this.post;

        return card({
          ...this.post,
          url,
          title,
          image: { url: image },
          wrap: false,
          details: {
            Author: author,
            Date: formatDate(date, { showTime: false })
          },
          useCta: true,
          icons: {
            'arrow-right': arrowRight
          }
        });
      }
    };
  }
});

const request = (endpoint = '', params) =>
  fetch(`/_/blog/${endpoint}?${new URLSearchParams(params)}`).then((response) =>
    response.json()
  );

const getTaxonomy = (taxonomy) =>
  request(`taxonomies/${taxonomy.replace(/^tag$/, 'post_tag')}`);

const getTerm = (term, taxonomy) => {
  let search = term.replace(/\W&\W/, ' &amp; ').toLowerCase();

  return request(taxonomy, {
    search,
    _fields: ['id', 'name', 'slug', 'link']
  }).then((response) =>
    response.find(({ name, slug }) =>
      [name, slug].map((s) => s.toLowerCase()).includes(search)
    )
  );
};

const getPosts = (params) =>
  request(`posts`, {
    ...params,
    per_page: 4,
    _embed: ['wp:featuredmedia', 'author'],
    _fields: [
      'id',
      'date',
      'link',
      'title',
      '_links',
      'yoast_head_json.og_image'
    ]
  }).then((response) =>
    response
      .map(({ title, ...rest }) => {
        try {
          return {
            ...rest,
            title: title.rendered,
            author: getAuthor(rest),
            image: getImage(rest)
          };
        } catch (e) {
          console.error(e);
        }
      })
      .filter(Boolean)
  );

const getAuthor = ({ _embedded }) => {
  try {
    return _embedded.author.map(({ name }) => name).join(', ');
  } catch (e) {
    console.error(e);
    return 'QAGOMA';
  }
};

const getImage = ({ _embedded, yoast_head_json }) => {
  try {
    let urls = _embedded['wp:featuredmedia']
      .flatMap(({ media_details }) =>
        Object.values(media_details.sizes)
          .sort(({ width: a }, { width: b }) => b - a)
          .map(({ source_url }) => source_url)
      )
      .filter(Boolean);

    return `/_/blog/${urls[0].split('wp-content/').pop()}`;
  } catch (e) {
    console.error(e);

    try {
      return yoast_head_json.og_image[0].url;
    } catch (e) {
      console.error(e);
    }
  }
};

export default init;
