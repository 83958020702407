const { imageHtml } = require('../_partials/image.js');

module.exports = ({ items, blockHelpers, ...data }) => {
  if (!items) return;

  return `<section x-id="['carousel']" class="block-imageCarousel" x-data="imageCarousel" ${blockHelpers.id(
    data
  )}>
    <div x-data="carousel" x-bind="root">
      <ul x-bind="viewport">
        ${items
          .map(
            ({ image, caption }, i) =>
              `<li x-bind="slide">${
                image && imageHtml({ ...image, height: 540 }, caption)
              }</li>`
          )
          .join('\r\n')}
      </ul>

      <template x-if="carousel">
        <div x-bind="buttons">
          <button x-bind="dirButton('prev')"></button>
          <button x-bind="dirButton('next')"></button>
        </div>
      </template>
    </div>
  </section>`;
};
