const { getFile } = require('@sanity/asset-utils');
const { toHTML, escapeHTML, uriLooksSafe } = require('@portabletext/to-html');
const Icon = require('../icon.js');

const client = require('./client.js');
const { eventUrl } = require('../../$content/events.js');
const { imageHtml } = require('../../_includes/_partials/image.js');
const imageUrlBuilder = require('@sanity/image-url')(client);

module.exports = (blocks = [], data) => {
  if (blocks && !Array.isArray(blocks)) blocks = [blocks];
  if (!blocks?.length > 0) return ``;

  const internalLink = ({
    children,
    value,
    value: { reference, title, calendarFilters, hash }
  }) => {
    try {
      if (!data?.links) return children;

      let id = reference?._id || reference?._ref || value?._id || value?._ref;

      if (!id) return children || title;

      if ((reference?._type || value?._type) == 'event')
        return event({ children, value: reference });

      let url = data.links[id];

      if (!url)
        throw new Error(`Failed to resolve internal link with id: ${id}`, {
          cause: { value, data }
        });

      if (hash) url += `#${hash}`;

      if (calendarFilters) {
        url = [
          url,
          new URLSearchParams(
            calendarFilters.map(({ _type, name }) => [
              _type.split('.').pop().replace('Feature', '').toLowerCase(),
              name
            ])
          )
        ].join('?');
      }

      return `<a href="${url}">${children || title}</a>`;
    } catch (e) {
      if (children) {
        return children;
      } else {
        throw e;
      }
    }
  };

  const svg = ({ children, value: { attributes } }) =>
    `<svg ${Object.entries({
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'currentColor',
      viewBox: '0 0 100 100',
      ...attributes
    })
      .map(([key, val]) => `${escapeHTML(key)}="${escapeHTML(val)}"`)
      .join(' ')}>${children}</svg>`;

  const documentList = ({ value: { documents, layout = '' } }) => {
    if (!documents) return;

    return (
      documents &&
      `<ul class="document-list${
        layout ? ` document-list--${layout}` : ``
      }">${documents
        .map((document) => {
          const { asset } = getFile(document, client.config());
          const { extension } = asset;

          return `<li>
          <a href="${asset.url}">
            ${Icon(
              extension.match(/(doc|ppt)x?|pdf/)
                ? extension.replace('x', '')
                : 'link'
            )}

            ${document.displayName || document.asset._ref}
          </a>
        </li>`;
        })
        .join('')}
    </ul>`
    );
  };

  const event = ({ children, value: { title, subtitle, ...event } }) => {
    let url = eventUrl(event);

    return `<a href="${url}">${
      children || [title, subtitle].filter(Boolean).join(': ') || url
    }</a>`;
  };

  const page = ({ children, value: { title, hash } }) => {
    let url = data.links[id];

    if (hash) url += `#${hash}`;

    return `<a href="${url}">${children || title || url}</a>`;
  };

  const contactLink = ({ children, value }) => {
    let { type, reference, subject } = value;
    let contact = reference[type];

    if (!contact) return children || contact;

    return `<a href="${type == 'emailAddress' ? 'mailto:' : 'tel:'}${contact
      .replace(/\s/g, '')
      .replace('(0)', '')}${
      subject && type == 'emailAddress'
        ? `?subject=${encodeURIComponent(subject)}`
        : ``
    }">${children || contact}</a>`;
  };

  const contactInformation = ({ value, isInline }) => {
    let { emailAddress, phone, title, firstName, lastName, role } = value;

    if (isInline) return value.join(' ');

    const isPerson = firstName || lastName || role;

    if (isPerson) title = [firstName, lastName].join(' ') || role;

    return `<section class="contact-information">
      ${title ? `<h1>${title}</h1>` : ``}
      ${isPerson && title != role ? `<h2>${role}</h2>` : ``}
  
      <ul>${[
        phone &&
          `<a href="tel:${escapeHTML(
            phone.replace(/\s/g, '').replace('(0)', '')
          )}">
            ${Icon('phone')} ${escapeHTML(phone)}
          </a>`,

        emailAddress &&
          `<a href="mailto:${escapeHTML(emailAddress)}">
            ${Icon('envelope')} ${escapeHTML(emailAddress)}
          </a>`
      ]
        .filter((x) => x)
        .map((x) => `<li>${x}</li>`)
        .join('')}</ul>
    </section>`;
  };

  const sponsorLogos = ({ value: { sponsors } }) => {
    sponsors.map((sponsor) =>
      Object.assign(sponsor, {
        logo:
          sponsor.logos &&
          sponsor.logos[0] &&
          imageUrlBuilder.image(sponsor.logos[0]).url()
      })
    );

    return `<ul class="logo-list" x-data="proportionalImageGroup">${sponsors
      .filter(({ logo }) => logo)
      .map(
        ({ logo, name, website, scaleMultiplier }) => `<li${
          scaleMultiplier ? ` style="font-size:${scaleMultiplier}em"` : ``
        }>
        ${website ? `<a href="${website}" target="_blank" class="logo">` : ``}
          <img src="${logo}" title="${name}" crossorigin="anonymous">
        ${website ? `</a>` : ``}
      </li>`
      )
      .join(' ')}</ul>`;
  };

  const image = ({ value }) =>
    imageHtml({
      ...value,
      ...value.metadata.dimensions,
      classes: 'content-image',
      fit: 'max'
    });
  const iframe = ({ value: { url } }) =>
    `<iframe src="${url}?embed=true" width="1920" height="768" frameborder="0" allow="compute-pressure; accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowfullscreen></iframe>`;

  const youtubeVideo = ({ value: { id } }) => `<figure>
    <iframe src="https://www.youtube-nocookie.com/embed/${id}" width="1920" height="1080"></iframe>
  </figure>`;

  const endnotesBlock = [];

  blocks.push({
    _type: 'endnotes',
    endnotes: endnotesBlock
  });

  const endnotes = ({ value: { endnotes } }) => {
    if (!endnotes?.length > 0) return ``;

    const heading = ({ value: { _key }, children }) => {
      if (_key === 'endnotes') {
        return `<h2 id="endnotes">${children}</h2>`;
      }

      return `<p>${children}</p>`;
    };

    const content = [
      {
        _key: 'endnotes',
        _type: 'block',
        children: [
          {
            _type: 'span',
            text: 'Endnotes'
          }
        ],
        style: 'heading'
      },
      ...endnotes.map((endnote) => ({
        ...endnote,
        listItem: 'number'
      }))
    ];

    return toHTML(content, {
      components: {
        listItem: ({ children, value: { noteId, referenceId } }) =>
          `<li><span id="${noteId}"><a href="#${referenceId}" title="Jump to reference" aria-label="Jump to reference">^</a> ${children}</span></li>`,
        block: {
          heading,
          h1: heading,
          h2: heading,
          h3: heading,
          h4: heading,
          h5: heading,
          h6: heading
        }
      }
    });
  };

  return toHTML(blocks, {
    components: {
      types: {
        contactInformation,
        contactLink,
        documentList,
        sponsorLogos,
        html: ({ value: { contents } }) => contents,
        path: ({ value: { path } }) => `<path d="${escapeHTML(path)}"/>`,
        event,
        page: internalLink,
        image,
        iframe,
        youtubeVideo,
        endnotes
      },
      marks: {
        contactLink,
        internalLink,
        link: ({ children, value }) => {
          const { target, href = '', _key } = value;

          const looksSafe = uriLooksSafe(href);

          return looksSafe
            ? `<a id="${_key}" href="${escapeHTML(href)}"${
                target ? ` target="${target}"` : ``
              }>${children}</a>`
            : children;
        },
        endnote: ({ children, value }) => {
          if (!this.endnoteCounter) this.endnoteCounter = 0;

          let noteId = `cite-note-${value._key}`,
            referenceId = `cite-ref-${value._key}`;

          endnotesBlock.push(
            ...value.content.map((block) => ({ ...block, noteId, referenceId }))
          );

          return `<span id="${referenceId}">${children}<sup><a href="#${noteId}">[${++this
            .endnoteCounter}]</a></sup></span>`;
        }
      },
      block: {
        normal: ({ children }) =>
          children ? `<p prevent-widows>${children}</p>` : ``,
        small: ({ children }) =>
          children ? `<p prevent-widows><small>${children}</small></p>` : ``,
        columns: ({ children }) => `<div class="columns">${children}</div>`,
        lang: ({ children, value: { lang } }) =>
          `<p lang="${lang}" dir="auto">${children}</p>`,
        endnotes: () => ``,
        svg
      }
    },
    onMissingComponent: false
  });
};
