const { formatYearRange } = require('../../utils/dates.js');
const { formatVenues } = require('../../utils/venues.js');
const { eventFormatDate } = require('../../$content/events.js');
const { grid } = require('./grid.js');
const { card: defaultCard } = require('./card.js');

const Icon = require('../../utils/icon.js');

const collection = (
  title,
  items,
  { columns = 4, max = 100, link, ...params } = {}
) => {
  if (!items.length) return ``;

  if (link) {
    if (!link?.url) link = { url: link };
    if (!link?.text) link.text = `See all ${title}`;
  }

  if (max != -1) {
    items = items.slice(0, max);
  }

  return grid({
    title,
    columns,
    link,
    ...params,
    items: items.map((event) => card({ ...params, ...event }))
  });
};

const card = (event) =>
  defaultCard({
    ...event,
    title: getTitle(event),
    tag: getTag(event),
    pretitle: getCategoriesOrType(event),
    details: getDetails(event)
  });

const getTitle = ({ title, subtitle, ...event }) =>
  [
    title,
    [subtitle, getFilmDetails(event)]
      .filter(Boolean)
      .map((x) => `<span>${x}</span>`)
  ]
    .flat()
    .join(' ');

const getTag = ({ displayUpcoming, type, start }) =>
  (displayUpcoming ??
    (type?.match(/program|exhibition/) && new Date(start) > new Date())) &&
  `Coming Soon`;

const getFilmDetails = ({ releaseDate, classification }) =>
  [
    ['Release Date', getFilmDates({ releaseDate })],
    ['Age Restriction/Classification', classification]
  ]
    .filter(([k, v]) => Boolean(v))
    .map(([k, v]) => `<span title="${k}">${v}</span>`)
    .join(` `);

const getFilmDates = ({ releaseDate }) => {
  try {
    let dates = (Array.isArray(releaseDate) ? releaseDate : [releaseDate])
      ?.flatMap((d) => d && d?.match(/\d{4}(-\d{2}-\d{2}|\b)/g))
      ?.map((d) => d && new Date(d).getTime())
      ?.filter(Boolean);

    if (dates?.length)
      return formatYearRange(Math.min(...dates), Math.max(...dates));
  } catch (e) {
    console.log(e);
  }
};

const getDetails = ({ showAdmission = true, ...event }) =>
  Object.fromEntries(
    [
      ['When', getDate(event)],
      ['Where', getVenues(event)],
      ['Admission', showAdmission && getAdmission(event)]
    ].filter(([, v]) => Boolean(v))
  );

const getDate = ({
  type,
  start,
  end,
  showDate = true,
  showPermanent = true,
  showDay
}) =>
  eventFormatDate({ type, start, end }, { showDate, showPermanent, showDay });

const getVenues = ({ venues }) => {
  return (
    venues &&
    formatVenues(venues, false)
      .replace(/Queensland Art Gallery/g, 'QAG')
      .replace(/Gallery of Modern Art/g, 'GOMA')
      .replace(/^QAG, GOMA$/, 'QAGOMA')
  );
};

const getAdmission = ({ end, is_sold_out, waitlist_available, canceled, ticketing, externalEvent, icons, ...event }) => {
  const text = (text) => {
    // Test for HTML, otherwise wrap in a span tag
    const htmlRegex = /<[a-z][\s\S]*>/i;
    return !htmlRegex.test(text) ? `<span>${text}</span>` : text;
  }

  // These conditions have priority
  if (end && new Date(end) < new Date()) return text('Event Ended');
  if (canceled) return text('Event Cancelled');
  if (is_sold_out && !waitlist_available) return text('Sold Out');

  const buttonHtml = (url, text) => `<a href="${url}" class="button button-sm">${text}</a>`;
  // These conditions may be overridden with custom text
  if (!!ticketing?.custom) {
    if (!!externalEvent?.url) return buttonHtml(externalEvent.url, ticketing.custom);
    return text(ticketing.custom);
  }

  if (!!externalEvent?.url) {
    return buttonHtml(
      externalEvent.url,
      is_sold_out && waitlist_available ? 'Join Waitlist' : getCallToAction(externalEvent, icons)
    );
  }

  return text(!!ticketing?.contact ? `Free, Bookings Required` : `Free`);
};

const getCallToAction = ({ type, is_free }, icons) => {
  if (type === 'zoom') return `Register`;
  if (!!is_free) return `Book Now`;
  if (is_free) return `Free, Bookings Required`;
  return `${Icon('currency', false, icons)} Buy Tickets`;
};

const getCategoriesOrType = ({
  context,
  categories = [],
  audiences,
  is_online,
  type
}) => {
  const list = new Set();

  categories?.forEach(({ name }) => list.add(name));

  if (type === `cinema-screening`) list.add('Cinema');
  if (is_online) list.add('Online');

  audiences?.forEach(({ _id, _ref }) => {
    if ((_id || _ref) == 'd718f657-2a27-4ec4-9276-20455ae15cc5-')
      list.add('Members');

    if ((_id || _ref) == `20bcd065-3e9e-4612-a3f3-7f32300c354a-`)
      list.add(`Kids`);
  });

  list.delete(context);

  return Array.from(list).join(', ');
};

module.exports = { collection, card, getAdmission, getTitle };
