﻿import Alpine from 'alpinejs';
import * as preview from './components/preview.js';

import focus from '@alpinejs/focus';
import persist from '@alpinejs/persist';
import collapse from '@alpinejs/collapse';
import header from './components/header.js';
import search from './components/search.js';
import alerts from './components/alerts.js';
import imageCaption from './components/imageCaption.js';
import addToCalendar from './components/addToCalendar.js';
import modal from './components/modal.js';

import { date as formatDate, formatDays, formatTime } from './utils/dates.js';

import { formatVenues } from './utils/venues.js';

Alpine.data('preview', preview.component);

Alpine.plugin(header);
Alpine.plugin(search);
Alpine.plugin(alerts);
Alpine.plugin(imageCaption);
Alpine.plugin(addToCalendar);
Alpine.plugin(modal);
Alpine.plugin(focus);
Alpine.plugin(persist);
Alpine.plugin(collapse);

Alpine.magic('formatDate', () => formatDate);
Alpine.magic('formatDays', () => formatDays);
Alpine.magic('formatTime', () => formatTime);
Alpine.magic('formatVenues', () => formatVenues);

window.Alpine = Alpine;

export default Alpine;
