const { client: sanity, toHTML } = require('../utils/sanity/index.js');

sanity.config({ withCredentials: false });

export const init = (Alpine) => {
  Alpine.data('alert', component);
};

export const component = function () {
  return {
    alerts: this.$persist('').using(sessionStorage),
    async init() {
      if (!this.alerts) this.alerts = await getAlerts();
    }
  };
};

const getAlerts = () =>
  sanity
    .fetch(
      `*[_type == "alert" && (!defined(start) || dateTime(now()) > dateTime(start)) && (!defined(end) || dateTime(now()) < dateTime(end))].content`
    )
    .then((alerts) => {
      if (alerts.length > 1)
        return `<ul>${alerts
          .map((alert) => `<li>${toHTML(alert)}</li>`)
          .join('')}</ul>`;

      if (alerts.length) return toHTML(alerts[0]);

      return false;
    });

export default init;
