﻿const parentProjection = (depth = 10, index = 0) =>
  index < depth
    ? `parent->{ _id, _type, title, "slug": slug.current, ${parentProjection(
        depth,
        ++index
      )}}`
    : ``;

const assetProjection = `{ ..., ...asset-> }`;

const imageProjection = `image${assetProjection}`;

const videoProjection = `
"video": coalesce(video, branding.videos[type == "banner.web"][0])${assetProjection}`;

const calendarFiltersProjection = `
defined(calendarFilters) && reference._ref == 'calendar' => {
  calendarFilters[]{
    "name": @->name,
    _type
  }
}`;

const linkProjection = `{
  ...,
  defined(reference) => {
    reference->{
      ...,
      ...file.asset->
    }
  },
  ${calendarFiltersProjection}
}`;

const portableTextProjection = `{
  ...,
  defined(_ref) => @->,
  defined(sponsors) => {
    sponsors[]->{
      ...,
      logos[]{ ..., ...asset-> }
    }
  },
  defined(documents) => {
    documents[]{ ..., ...asset-> }
  },
  defined(markDefs) => {
    markDefs[]${linkProjection}
  },
  defined(children) => {
    children[]{
      ...select(
        defined(_ref) => @->{
          _id,
          _type,
          slug,
          defined(type) => { type },
          defined(parent) => { parent },
          defined(title) => { title },
          defined(subtitle) => { subtitle },
          defined(start) => { start }
        },
        {
          ...,
          defined(reference) => { reference-> }
        }
      )
    }
  }
}`;

const blocksProjection = `{
  ...,
  ...select(
    _type == "block:venueShowcase" => {
      ...,
      "venues": select(
        type == "venueType" && defined(venueType) => *[_type == "venue" && type == ^.venueType],
        venues[]->
      )
    }{
      "venues": select(defined(functionTypes) => venues[functionTypes match ^.functionTypes], venues)[]{
        ...,
        contactInformation->,
        bookingUrl,
        floorPlan{..., ...asset->},
        images[]{..., ...asset->},
        "parent": parent->,
        description[]${portableTextProjection}
      }
    },
    _type == "block:eventExpectations" => {
      ...,
      "text": [...introduction, ...content]
    }
  ),
  _type == "block:emailSignup" => {
    "text": text[style != "small"],
    "terms": text[style == "small"]
  }
}{
  ...,
  count(text) > 0 => {
    text[]${portableTextProjection}
  },
  defined(image) => { ${imageProjection} },
  defined(items) => {
    items[]{
      ...,
      defined(image) => {
        ${imageProjection}
      },
      defined(images) => {
        images[]{ ..., ...asset-> },
      },
      count(text) > 0 => {
        text[]${portableTextProjection}
      },
      defined(link) => {
        link${linkProjection}
      },
      defined(callToActions) => {
        callToActions[]${linkProjection}
      }
    }
  },
  defined(rows) => {
    rows[]{
      ...,
      columns[]{
        ...,
        text[]${portableTextProjection}
      }
    }
  },
  defined(sponsors) => {
    sponsors[]{
      ...,
      ...sponsor->{ ..., "logos": logos[].asset-> }
    }
  },
  defined(link) => {
    link${linkProjection}
  }
}`;

const venuesProjection = `
defined(venues) => {
  "venues": array::compact([...venues, ...venues[]->parent, ...venues[]->parent->parent])[]{
    ...,
    _type == "reference" => {
      ...@->
    }
  }{
    _id,
    type,
    title,
    expandedTitle,
    "parent": parent->_id
  } | order(title asc)
}`;

const scoreProjection = `
'_score': select(
  defined(priority) => select(
    !defined(end) => (priority*0.75),
    dateTime(end) >= dateTime($today) => select(
      dateTime(start) > dateTime($today) => round(priority - (priority * (((dateTime(start) - dateTime($today)) / 86400) / 60))),
      priority
    )
  ), 0)`;

// Used on Homepage and related events listings to get 4 events that contain at least one 'kids', 'cinema' and 'tour' type if possible. Sorts by priority, weighted by start date
const relatedEventsProjection = `]{
    ...,
    ${scoreProjection}
  } | order(_score desc, start asc, end asc, title asc)
  }{
    events,
    'cinema': events[type=='cinema-screening' || references('e99c78cb-04c6-46ed-a5bf-af0d54c480e5')]
  }{
    ...,
    'kids': events[!(_id in ^.cinema[]._id) && references('20bcd065-3e9e-4612-a3f3-7f32300c354a-')]
  }{
    ...,
    'tour': events[!(_id in (^.cinema + ^.kids)[]._id) && references('01c25370-0784-4e8d-ae23-41141c089fb8')]
  }{
    ...,
    'other': events[!(_id in (^.cinema + ^.kids + ^.tour)[]._id)]
  }{
    'events': array::compact(
      [kids[0], cinema[0], tour[0], other[0]] +
      ((kids[1..4] + cinema[1..4] + tour[1..4] + other[1..4])|order(start asc))
    )[0...4`;

module.exports = {
  parentProjection,
  imageProjection,
  videoProjection,
  portableTextProjection,
  blocksProjection,
  venuesProjection,
  scoreProjection,
  relatedEventsProjection
};
