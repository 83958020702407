const _ = require('lodash');

export default function DefaultField(props) {
  const {
    required,
    inputName,
    dependentValue,
    dependentField,
    dependentOperator,
    requiredIf
  } = props;

  function init() {
    Alpine.effect(() => {
      if (typeof this.dependentField !== 'undefined') {
        const { dependentFieldValue, dependentOperator, dependentValue } = this;

        this.active = dependentCondition(
          dependentFieldValue,
          dependentValue,
          dependentOperator
        );
      }
    });

    Alpine.effect(() => {
      this.isRequired =
        this.requiredIf?.some((conditions) =>
          Object.entries(conditions).every(([field, pattern]) =>
            this.values[field]?.match(new RegExp(pattern))
          )
        ) ??
        this.required ??
        false;
    });

    Alpine.effect(() => {
      const { isRequired, checkValidity } = this;

      if (!isRequired && checkValidity) {
        this.isValid = checkValidity();
      }
    });

    if (this.addField) this.addField(Alpine.$data(this.$el));
  }

  return {
    dependentFieldName: dependentField,
    get dependentFieldValue() {
      if (this.dependentFieldName)
        return Alpine.$data(this.$el).values[this.dependentFieldName];
    },
    get isArrayValue() {
      return typeof this.isSingle !== 'undefined' && !this.isSingle;
    },
    init,
    dependentField,
    dependentValue,
    dependentOperator,
    requiredIf,
    inputName,
    active: true,
    value: undefined,
    isRequired: Boolean(required),
    isValid: false,
    showValidationMessage: false,
    checkValidity: undefined,
    setCustomValidity: undefined,
    focusValueMissing: false,
    wrapper: {
      'x-model': 'values[inputName]',
      'x-modelable': 'value',
      'x-show': 'active'
    },
    input: {
      'x-init'() {
        const { $el } = this;

        this.inputName = $el.name;

        if (this.isArrayValue) {
          this.value = [];
        } else {
          if (this.$el.type !== 'checkbox' || this.$el.checked) {
            this.value = $el.value;
          } else {
            this.value = false;
          }
        }

        if (!this.values.hasOwnProperty(this.inputName)) {
          this.values[this.inputName] = this.value;
        }

        this.checkValidity = $el.checkValidity.bind($el);
        this.setCustomValidity = this.$el.setCustomValidity.bind(this.$el);
        this.validity = $el.validity;
      },
      ':disabled': '!active',
      ':required': `isRequired`,
      'x-ref': 'input',
      'x-model': 'value',
      '@focus': `focusValueMissing = $el.validity.valueMissing`,
      '@input':
        `showValidationMessage && (isValid = $el.checkValidity()),` +
        `(isValid || value === '' || (focusValueMissing && !$el.validity.valueMissing)) && (showValidationMessage = false)`,
      '@blur': `showValidationMessage = !$el.checkValidity()`,
      '@invalid': `isValid = false`
    },
    ...props
  };
}

function dependentCondition(currentValue, validValue, operator) {
  const compare = (a, b) => {
    if (`${a}` === b || _.isEqual([a], b) || _.isEqual([`${a}`], b))
      return true;
  };

  try {
    switch (operator) {
      default:
      case 'eq':
        return _.isEqualWith(currentValue, validValue, compare);

      case 'neq':
        return !_.isEqualWith(currentValue, validValue, compare);

      case 'set_any':
        return validValue.includes(currentValue);
      case 'set_not_any':
        return !validValue.includes(currentValue);

      // case 'set_eq': //(For enumerated properties only) Meaning that the property has exactly the values in a semi-colon separated list.
      // case 'set_neq': //(For enumerated properties only) Meaning that the property doesn't have exactly the values in a semi-colon separated list.
      // case 'set_all': //(For enumerated properties only) Meaning that the property has all of the values in a semi-colon separated list.

      case 'contains':
        return currentValue.match(new RegExp(validValue));

      case 'gt':
        return currentValue > validValue;
      case 'gte':
        return currentValue >= validValue;
      case 'lt':
        return currentValue < validValue;
      case 'lte':
        return currentValue <= validValue;

      case 'is_empty':
        return !Boolean(currentValue);
      case 'is_not_empty':
        return Boolean(currentValue);
    }
  } catch (e) {
    console.error(e);
    return true;
  }
}
