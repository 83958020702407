﻿export const init = (Alpine) => {
  Alpine.data('collapsible', collapsible);
};

export const collapsible = ({
  template: defaultTemplate = `%s`,
  showTemplate: defaultShowTemplate = 'Show %d more',
  hideTemplate: defaultHideTemplate = 'Hide %d',
  selector: defaultSelector = 'li',
  minimum: defaultMinimum = 3
} = {}) => ({
  nodes: null,
  count: 0,
  container: null,
  state: {},
  hasVisibleItems: undefined,
  init() {
    this.$el.classList.add('collapsible');

    if (!this.$el.querySelector('button[x-bind*=collapsibleToggle]')) {
      this.$el.insertAdjacentHTML(
        'beforeend',
        `<button x-bind='collapsibleToggle'></button>`
      );
    }

    this.$watch('state', () => {
      this.hasVisibleItems = Object.values(this.state).some(
        ({ show, minimum }) => minimum > 0 || show
      );
    });

    this.$el.addEventListener('collapsible.toggle', (e) => {
      e.stopPropagation();

      let state = e.detail,
        { selector, minimum, show } = state;

      this.state[selector] = state;

      let items = this.$el.querySelectorAll(selector);

      items.forEach((el, i) => {
        if (i >= minimum) el.style.display = show ? '' : 'none';
      });

      state.count = items.length - minimum;
    });
  },
  collapsibleToggle({
    template = defaultTemplate,
    showTemplate = defaultShowTemplate,
    hideTemplate = defaultHideTemplate,
    ...params
  } = {}) {
    return {
      ['x-data']() {
        let selectorState = Alpine.reactive({
          selector: defaultSelector,
          minimum: defaultMinimum,
          ...params,
          show: false,
          count: 0
        });

        return {
          set show(show) {
            selectorState.show = show;
          },
          get show() {
            return selectorState.show;
          },
          get count() {
            return selectorState.count;
          },
          init() {
            Alpine.effect(
              () => (
                this.show, this.$dispatch('collapsible.toggle', selectorState)
              )
            );
          }
        };
      },
      ':class': "{ open: show, 'collapsible__toggle': true }",
      ['@click']: `show = !show`,
      ['x-show']: 'count > 0',
      ['x-text']() {
        return template
          .replace('%s', this.show ? `${hideTemplate}` : `${showTemplate}`)
          .replace('%d', this.count);
      }
    };
  }
});

export default init;
