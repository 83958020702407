﻿const { client: sanityClient } = require('../../utils/sanity/index.js');
import { card } from '../../_includes/_partials/events.js';

import { projection, eventWithUrl } from '../../$content/events.js';

const sanity = sanityClient.config({
  useCdn: true,
  useCredentials: false,
  token: false
});

const icons = {
  currency: new URL(`/assets/svgs/currency.svg`, import.meta.url)
};

export const init = (Alpine) => {
  Alpine.data('featuredEvents', component);
};

export const component = (query) => ({
  events: [],
  async init() {
    this.events = await sanity.fetch(
      `*[_type == "event" && defined(end) && dateTime(end) >= dateTime($today)]{
        ...parent->,
        ...,
        "type": coalesce(parent->type, type)
      }[${query}]|order(start asc, coalesce(priority, 0) desc, end asc, title asc)[0...4]${projection}`,
      {
        today: new Date(new Date().setHours(0, 0, 0, 0)).toISOString()
      }
    );
  },
  card(event) {
    return {
      ['x-html']() {
        return card({ ...eventWithUrl(event), wrap: false, icons });
      }
    };
  }
});
