import Field from './fields/index.js';

export const init = (Alpine) => {
  Alpine.data('form', Form);
};

export function Form() {
  return {
    recaptchaSiteKey: undefined,
    values: {},
    form: {
      'x-init': 'values = Object.fromEntries(new FormData($el).entries())',
      'x-ref': 'form',
      ':class': 'submitInProgress && "processing"'
    },
    submitButton: {
      'x-ref': 'submitButton',
      ':disabled': 'submitInProgress'
    },
    submitInProgress: false,
    submitHandlers: new Set(),
    addSubmitHandler: function (handler) {
      return this.submitHandlers.add(handler);
    },
    init() {
      try {
        this.recaptchaSiteKey = document.querySelector('script[src^="https://www.google.com/recaptcha/api.js?render="]').src.match(/render=([^?&]*)/)[1]
      } catch (e) {
        console.error(e)
      }

      this.$el.addEventListener('submit', (event) => {
        if (!this.submitInProgress) {
          this.submitInProgress = true;

          const responses = Array.from(this.submitHandlers).map((handler) =>
            handler({
              event,
              resubmit: event.target.requestSubmit.bind(event.target)
            })
          );

          if (responses.every((response) => response === true)) return true;

          Promise.all(responses).finally(() => (this.submitInProgress = false));
        }

        event.preventDefault();

        return false;
      });
    },
    captcha() {
      return {
        type: 'text',
        'x-show': 'false',
        'x-ref': 'captcha',
        'x-model': 'token',
        'x-data'() {
          return {
            token: '',
            init() {
              let inProgress = false,
                timeoutID;

              const getToken = () => {
                return new Promise((resolve, reject) => {
                  grecaptcha
                    .execute(this.recaptchaSiteKey, { action: 'submit' })
                    .then(resolve)
                    .catch(reject);
                });
              };

              const clearToken = () => {
                this.token = '';
              };

              const startTimeout = () => {
                clearTimeout(timeoutID);
                timeoutID = setTimeout(() => clearToken(), 30000);
              };

              grecaptcha.ready(() => {
                this.addSubmitHandler(({ resubmit }) => {
                  if (this.token) return true;

                  if (!inProgress) {
                    inProgress = getToken()
                      .then((token) => {
                        this.token = token;

                        this.$nextTick(() => {
                          resubmit();
                          startTimeout();
                        });
                      })
                      .catch(console.error)
                      .finally(() => {
                        inProgress = false;
                      });
                  }

                  return inProgress;
                });
              });
            }
          };
        }
      };
    },
    field: Field,
    fieldset: {
      'x-show': 'active',
      'x-data'() {
        return {
          fields: [],
          get active() {
            return this.fields.some(({ active }) => active);
          },
          addField(field) {
            this.fields.push(field);
          }
        };
      }
    }
  };
}
