// export * as styles from 'url:./videoShowcase.scss';
import { default as youtubeAPI, playerSettings } from '../youtube';

import Icon from '../../utils/icon.js';

const icons = {
  'arrow-right': new URL(`/assets/svgs/arrow-right.svg`, import.meta.url)
};

export const init = (Alpine) => {
  Alpine.data('videoShowcase', component);
};

export const component = ({
  videos: videoIds = [],
  playlist: playlistId = null,
  type = null
}) => {
  return {
    type,
    videoIds,
    playlistId,
    videos: null,
    playlist: null,
    player: null,
    selected: null,
    link: null,
    async init() {
      await youtubeAPI();

      Alpine.effect(async () => {
        if (this.type == 'playlist' && this.playlistId) {
          this.playlist = await fetchPlaylist(this.playlistId).then(
            (data) => (console.log(data), data)
          );
          this.videos = this.playlist.items.map(({ snippet, ...item }) => ({
            videoId: snippet.resourceId.videoId,
            title: snippet.title,
            thumbnail:
              snippet.thumbnails.maxres?.url ||
              snippet.thumbnails.high?.url ||
              snippet.thumbnails.medium?.url ||
              snippet.thumbnails.standard?.url ||
              snippet.thumbnails.default?.url,
            snippet,
            ...item
          }));
        } else {
          this.videos = (
            await fetchVideos(this.videoIds).then(
              (data) => (console.log(data), data)
            )
          ).items.map(({ id, snippet, ...item }) => ({
            videoId: id,
            title: snippet.title,
            thumbnail:
              snippet.thumbnails.maxres?.url ||
              snippet.thumbnails.high?.url ||
              snippet.thumbnails.medium?.url ||
              snippet.thumbnails.standard?.url ||
              snippet.thumbnails.default?.url,
            snippet,
            ...item
          }));
        }

        this.link = {
          url: `https://www.youtube.com/${
            this.playlist?.id
              ? `playlist?list=${this.playlist?.id}`
              : `/c/QAGOMA_Australia`
          }`,
          title: this.playlist?.snippet?.title
            ? `View ${this.playlist?.snippet?.title} Playlist`
            : 'Visit QAGOMA YouTube'
        };
      });

      Alpine.effect(() => (this.selected = this.videos && this.videos[0]));

      Alpine.effect(
        () =>
          this.selected &&
          this.player.loadVideoById &&
          this.player.loadVideoById(this.selected.videoId)
      );
    },
    player: {
      ['x-init']() {
        this.player = new YT.Player(this.$el, {
          ...playerSettings,
          videoId: this.selected.videoId
        });
      }
    },
    link: {
      [':class']: '`cta-see-more`',
      [':href']() {
        return `https://www.youtube.com/${
          this.playlist?.id
            ? `playlist?list=${this.playlist?.id}`
            : `/c/QAGOMA_Australia`
        }`;
      },
      ['x-html']() {
        return (
          (this.playlist?.snippet?.title
            ? `View ${this.playlist?.snippet?.title} Playlist`
            : `Visit QAGOMA YouTube`) + Icon('arrow-right', false, icons)
        );
      },
      ['target']: '_blank'
    }
  };
};

const request = async (endpoint, params) => {
  const request = await fetch(
    new URL(
      `${endpoint}?${new URLSearchParams({
        ...params,
        rel: 0,
        key: 'AIzaSyBlA-mxkUk_WB2bAM1itik7yn50iJ5p9qw'
      })}`,
      `https://youtube.googleapis.com/youtube/v3/`
    )
  );

  const response = await request.json();

  return response;
};

const fetchPlaylist = async (playlistId) => {
  const playlist = request('playlists', {
    id: playlistId,
    part: 'snippet'
  }).then((data) => data?.items?.[0]);

  const playlistItems = request('playlistItems', {
    part: 'snippet,contentDetails',
    playlistId: playlistId,
    maxResults: 3
  });

  return Promise.all([playlist, playlistItems]).then(
    ([playlist, playlistItems]) => ({
      ...playlistItems,
      ...playlist
    })
  );
};

const fetchVideos = (videoIds) => {
  const response = request('videos', {
    part: 'snippet,contentDetails',
    id: videoIds.join(','),
    maxResults: 3
  });

  return response;
};

export default init;
