const toHTML = require('./blockContent.js');
const toText = require('./toText.js');
const client = require('./client.js');
const imageUrlBuilder = require('@sanity/image-url')(client);

const {
  getFile,
  getImageAsset,
  getAssetDocumentId,
  buildFileUrl
} = require('@sanity/asset-utils');

module.exports = {
  client,
  toHTML,
  toText,
  buildFileUrl: (file) => buildFileUrl(file, client.config()),
  getFile: (file) => getFile(file, client.config()),
  getImageAsset: (asset) => getImageAsset(asset, client.config()),
  getAssetDocumentId: (asset) => getAssetDocumentId(asset, client.config()),
  imageUrlBuilder: (source) => imageUrlBuilder.image(source)
};
