const { date } = require('../utils/dates.js');

const {
  blocksProjection,
  portableTextProjection,
  venuesProjection,
  imageProjection,
  videoProjection
} = require('./_common.js');

const filmClassificationProjection = `
"classification": {
  "allAges": coalesce(allAges, false),
  ageRestriction,
  ...classification->
}{
  ...,
  "display": select(
    defined(ageRestriction) => coalesce(shortName, "Ages ") + string(ageRestriction) + "+",
    defined(shortName) => shortName,
    allAges => "All Ages"
  )
}`;

const screeningFilmsProjection = `
"end": coalesce(end, dateTime(start) + (math::sum(films[].runtime) * 60)),
"releaseDate": films[].releaseDate,
...{
  "classifications": films[].classification | order(coalesce(ageRestriction, select(
      display match "All Ages" => -10,
      display match "G" => -2,
      display match "PG" => -1,
      0
    )) desc)
}{
  "classification": classifications[0].display
}`;

const filmsProjection = `defined(films) => {
  "films": films[]->{
    ...,
    ${filmClassificationProjection}
  }{
    ...,
    "consumerAdvice": array::join(string::split(consumerAdvice, " | ")[@ != ^.classification.display], " | ")
  },
  start,
  end
}{
  films,
  ${screeningFilmsProjection}
}`;

const projection = `{
  ...,
  is_series_parent == true => {
    "series_id": externalEvent.id
  },
  type == "_instance" && defined(parent) => {
    ...parent->,
    ...,
    "type": parent->type,
    "end": coalesce(end, dateTime(start) + (dateTime(parent->end) - dateTime(parent->start))),
    "series_id": parent->externalEvent.id
  },
  ${venuesProjection},
  defined(screenings) => {
    "screenings": [
      ...screenings[]->,
      ...*[references(^.screenings[]._ref) && _type == "event" && type == "_instance"],
      ...*[references(^._id) && _type == "event" && type == "standard-event" && "e99c78cb-04c6-46ed-a5bf-af0d54c480e5" in categories[]._ref && ^._id in related[]._ref]
    ]{
      ...parent->,
      ...,
      "type": coalesce(parent->type, type),
    }{
      ...,
      "venues": array::compact([
        ...venues[],
        ...venues[]->parent,
        ...venues[]->parent->parent
      ])
    }
  }{
    ...,
    "films": *[_type == "film" && _id in array::compact(array::unique(^.screenings[].films[]._ref))]{
      ...,
      ${filmClassificationProjection}
    },
    "venues": *[_type == "venue" && _id in array::compact(array::unique(^.screenings[].venues[]._ref))]{
      _id,
      type,
      title,
      expandedTitle,
      "parent": parent->_id
    } | order(title asc)
  }{
    screenings[]{
      ...,
      films[]{ ...^.^.films[_id == ^._ref][0] },
      venues[]{ ...^.^.venues[_id == ^._ref][0] }
    }
  }
}{
  "externalEvent": coalesce(externalEvent, select(ticketing.type == "url" => ticketing, false)),
  ticketing,
  "is_sold_out": coalesce(is_sold_out, false),
  "waitlist_available": coalesce(waitlist_available, false),
  "canceled": coalesce(canceled, false),
  "is_series_parent": coalesce(is_series_parent, false),
  series_id,
  is_online,
  categories[]->,
  audiences[]->,
  accessibility[]->,
  _id,
  _rev,
  _type,
  type,
  "slug": slug.current,
  "title": coalesce(title, films[0]->title),
  "subtitle": coalesce(subtitle, films[0]->subtitle),
  ...{
    "image": coalesce(image, films[0]->image)
  }{
    ${imageProjection}
  },
  ${videoProjection},
  "introduction": summary[]${portableTextProjection},
  count(content) > 0 => {
    "blocks": content[]${blocksProjection}
  },
  start,
  end,
  priority,
  ${venuesProjection},
  defined(resources) => {
    "virtualTours": resources[_type == "virtualTour"]{
      ...,
      "venue": venue->title
    }
  },
  defined(sponsors) => {
    sponsors[]{
      ...,
      ...sponsor->{
        ...,
        "logos": logos[].asset->
      }
    }
  },
  branding,
  defined(screenings) => {
    screenings[]{
      ...films[0],
      ...
    }{
      type,
      title,
      subtitle,
      start,
      "slug": slug.current,
      "externalEvent": coalesce(externalEvent, ticketing),
      canceled,
      is_sold_out,
      waitlist_available,
      is_free,
      venues,
      defined(categories) => { categories[]-> },
      ${imageProjection},
      ${screeningFilmsProjection}
    } | order(start asc)
  }{
    "screenings": {
      "upcoming": screenings[dateTime(end) >= dateTime($today)],
      "past": screenings[dateTime(end) < dateTime($today)] | order(end desc)
    }
  },
  type == "cinema-screening" => {
    "program": *[references(coalesce(^.parent._ref, ^._id)) && _type == "event" && type == "cinema-program"][0]{
      title,
      type,
      start,
      end,
      "slug": slug.current,
      "parent": "cinema"
    }
  },
  "objects": coalesce(count(objects), 0),
  ${filmsProjection}
}`;

const getSlugDate = (date) =>
  Object.entries({
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }).map(([part], i, arr) =>
    new Date(date).toLocaleDateString('en-au', {
      timeZone: 'Australia/Brisbane',
      ...Object.fromEntries(arr.map(([k, v]) => [k, k == part ? v : undefined]))
    })
  ).join('-');

const isNormalEventType = (type) =>
  ['cinema-screening', 'standard-event'].includes(type);

const eventSlug = ({ slug, type, start }) => {
  slug = slug?.current || slug;

  if (isNormalEventType(type) && start) {
    try {
      start = new Date(start);

      return [slug, getSlugDate(start)].join('-');
    } catch (e) {
      console.error(e);
    }
  }

  return slug;
};

const eventUrl = ({ type = 'event', slug, start }) => {
  return [``, ...type.split('-')]
    .filter((x) => x !== 'standard')
    .concat(eventSlug({ type, slug, start }))
    .join('/');
};

const eventWithUrl = (event) => ({
  ...event,
  url: eventUrl(event)
});

const ticketUrl = ({ externalEvent, type: eventType, start }) => {
  if (externalEvent) {
    const { type, id, url } = externalEvent;

    if (url || id?.startsWith('http')) return url;

    if (id) {
      switch (type) {
        case `ticketure`:
          let url = `https://qagoma.t.qtix.com.au/events/${id}`

          try {
            if (isNormalEventType(eventType) && start)
              url = `${url}?date=${getSlugDate(new Date(start))}`;
          } finally {
            return url;
          }
        case `eventbrite`:
          return `https://www.eventbrite.com.au/e/${id}`;
      }
    }
  }

  return null;
};

const withTicketUrl = (event) => {
  const url = ticketUrl(event);

  if (event?.externalEvent) {
    Object.assign(event.externalEvent, { url });
  }

  Object.assign(event, { ticketUrl: event.externalEvent?.url });

  return event;
};

const eventFormatDate = ({ type, start, end }, opts) => {
  if (!end && opts?.showPermanent) return `Permanent`;

  try {
    return date(
      start,
      end,
      Object.assign(
        {
          showEndAsDuration: !!type?.match(/screening/),
          showTime: !type?.match(/exhibition|program/)
        },
        opts,
        {
          showDay: opts?.showDay ?? !type?.match(/exhibition|program/)
        }
      )
    );
  } catch (e) {
    console.log(e);
    return ``;
  }
};

exports.projection = projection;
exports.filmsProjection = filmsProjection;
exports.eventUrl = eventUrl;
exports.ticketUrl = ticketUrl;
exports.eventWithUrl = eventWithUrl;
exports.withUrl = eventWithUrl;
exports.withTicketUrl = withTicketUrl;
exports.eventFormatDate = eventFormatDate;
exports.isNormalEventType = isNormalEventType;
