﻿import { APCAcontrast, sRGBtoY } from 'apca-w3';


export const init = (Alpine) => {
  Alpine.data('imageCaption', component);
};

export const component = () => ({
  open: false,
  init() {
    let mod = { w: 1, h: 1 };

    const caption = this.$el,
      image = caption.closest('figure').querySelector('img'),
      button = caption.querySelector('button'),
      imageLoaded = new Promise((resolve) => {
        image.onload = resolve;
        if (image.complete) resolve();
      }).then(() => new Promise((resolve) => {
        let tmp = new Image()

        tmp.onload = () => {
          mod.w = tmp.naturalWidth / image.clientWidth
          mod.h = tmp.naturalHeight / image.clientHeight

          resolve()
        };

        tmp.src = image.currentSrc;
      }));

    imageLoaded.then(() => {
      this.$nextTick(() => {
        const [r, g, b] = sampleColor()

        let bgColor = sRGBtoY([r, g, b, 1]),
          light = APCAcontrast(bgColor, sRGBtoY([255, 255, 255, 1])),
          dark = APCAcontrast(bgColor, sRGBtoY([60, 60, 60, 1]));

        caption.style.setProperty('--c-background', `rgb(${r}, ${g}, ${b})`)
        caption.classList.toggle('reverse', Math.abs(dark) > light)
      });
    });

    caption.classList.remove('open');

    function sampleColor() {
      const canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      canvas.width = button.clientWidth
      canvas.height = button.clientHeight

      ctx.drawImage(
        image,
        (caption.offsetLeft + button.offsetLeft) * mod.w,
        (caption.offsetTop + button.offsetTop) * mod.h,
        canvas.width * mod.w,
        canvas.height * mod.h,
        0,
        0,
        canvas.width,
        canvas.height
      );

      let r = 0, g = 0, b = 0, a = 0;

      const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;

      const components = data.length;

      for (let i = 0; i < components; i += 4) {
        r += data[i];
        g += data[i + 1];
        b += data[i + 2];
        a += data[i + 3];
      }

      const pixelsPerChannel = components / 4;

      // The | operator is used here to perform an integer division:

      r = r / pixelsPerChannel | 0;
      g = g / pixelsPerChannel | 0;
      b = b / pixelsPerChannel | 0;
      a = a / pixelsPerChannel / 255;

      return [r, g, b]
    }
  }
});

export default init;
