module.exports = ({ title, subtitle, hashtags, blockHelpers, ...data }) => {
  let hashtagsHtml = hashtags
    ? hashtags
        .map(
          (hashtag) =>
            ` <a href="https://www.instagram.com/explore/tags/${hashtag.toLowerCase()}/" target="_blank">#${hashtag}</a>`
        )
        .join(` `)
    : ``;

  subtitle = `<p>${
    subtitle?.match(`#`)
      ? subtitle.replace(`#`, hashtagsHtml)
      : [subtitle, hashtagsHtml].filter(Boolean).join(' ')
  }</p>`;

  return `<section class="block-instagramFeed grid" x-data='instagramFeed(${JSON.stringify(
    { hashtags }
  )})' ${blockHelpers.id(data)}>
    <h1>${title}</h1>
    ${subtitle}

    <ul>
      <template x-for="post in posts">
        <li><a :href="post.permalink" :title="post.caption"><img :src="post.image"></a></li>
      </template>
    </ul>
  </section>`;
};
