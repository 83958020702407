const templates = [
  'exhibitionResourceFeed.11ty.js',
  'blogShowcase.11ty.js',
  'callToAction.11ty.js',
  'childrensResources.11ty.js',
  'collectionShowcase.11ty.js',
  'educationResources.11ty.js',
  'emailSignup.11ty.js',
  'eventFeed.11ty.js',
  'eventExpectations.11ty.js',
  'expandedText.11ty.js',
  'featuredEvents.11ty.js',
  'flexibleContent.11ty.js',
  'html.11ty.js',
  'keyPeople.11ty.js',
  'imageCarousel.11ty.js',
  'instagramFeed.11ty.js',
  'mediaReleases.11ty.js',
  'linkCarousel.11ty.js',
  'productShowcase.11ty.js',
  'promoBanner.11ty.js',
  'pullQuote.11ty.js',
  'sponsorBlock.11ty.js',
  'storiesShowcase.11ty.js',
  'table.11ty.js',
  'themesShowcase.11ty.js',
  'timeline.11ty.js',
  'venueShowcase.11ty.js',
  'videoShowcase.11ty.js'
];

module.exports = async function (data) {
  const { renderFile } = this;
  const { blocks, ...rest } = data;

  const blockHelpers = Object.defineProperties(
    {
      alternate(key, values = []) {
        if (!this._alternate?.[key]) this._alternate[key] = 0;
        return values[++this._alternate[key] % values.length];
      },
      oddOrEven(key) {
        return this.alternate(key, ['even', 'odd']);
      },
      id({ _key, _id, _type }) {
        return `id="c_${_key || _id || _type}"`;
      }
    },
    {
      _alternate: {
        enumerable: false,
        value: {}
      }
    }
  );

  return (
    blocks &&
    (
      await Promise.all(
        blocks.map((block) => {
          if (block?._type) {
            const template = templates.find((template) =>
              template.startsWith(block?._type?.replace('block:', ''))
            );

            if (template) {
              return renderFile(`${__dirname}/${template}`, {
                ...rest,
                ...block,
                blockHelpers
              });
            }
          }

          return ``;
        })
      )
    ).join('')
  );
};
