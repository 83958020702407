const { AssetCache } = require('@11ty/eleventy-fetch');

module.exports = async function (name, requestFunc, params = {}) {
  const serverless = !!process.env.ELEVENTY_SERVERLESS;

  const {
    duration = serverless ? '*' : '1d',
    directory = serverless ? 'cache' : '.cache'
  } = params;

  let asset = new AssetCache(name, directory);

  if (asset.isCacheValid(duration)) return asset.getCachedValue();

  let response = await requestFunc();
  if (!serverless) await asset.save(response || JSON.stringify(null), 'json');

  return response;
};
