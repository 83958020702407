export const init = (Alpine) => {
  Alpine.data('instagramFeed', component);
};

export const component = ({ hashtags }) => ({
  hashtags,
  posts: [],
  async init() {
    const request = await fetch(
      `/_/instagram/hashtag/${hashtags.join()}?limit=8`
    );
    this.posts = await request.json();
  }
});

export default init;
