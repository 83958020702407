const { getFile } = require('@sanity/asset-utils');
const { toHTML, escapeHTML, uriLooksSafe } = require('@portabletext/to-html');
const Icon = require('../icon.js');

const client = require('./client.js');
const { eventUrl } = require('../../$content/events.js');
const imageUrlBuilder = require('@sanity/image-url')(client);

module.exports = (blocks, data) => {
  const internalLink = ({
    children,
    value,
    value: { reference, title, calendarFilters, hash }
  }) => {
    try {
      if (!data?.links) return children;

      let id = reference?._id || reference?._ref || value?._id || value?._ref;

      if (!id) return children || title;

      if ((reference?._type || value?._type) == 'event')
        return event({ children, value: reference });

      let url = data.links[id];

      if (!url)
        throw new Error(`Failed to resolve internal link with id: ${id}`, {
          cause: { value, data }
        });

      if (hash) url += `#${hash}`;

      if (calendarFilters) {
        url = [
          url,
          new URLSearchParams(
            calendarFilters.map(({ _type, name }) => [
              _type.split('.').pop().replace('Feature', '').toLowerCase(),
              name
            ])
          )
        ].join('?');
      }

      return `<a href="${url}">${children || title}</a>`;
    } catch (e) {
      if (children) {
        return children;
      } else {
        throw e;
      }
    }
  };

  const svg = ({ children, value: { attributes } }) =>
    `<svg ${Object.entries({
      xmlns: 'http://www.w3.org/2000/svg',
      fill: 'currentColor',
      viewBox: '0 0 100 100',
      ...attributes
    })
      .map(([key, val]) => `${escapeHTML(key)}="${escapeHTML(val)}"`)
      .join(' ')}>${children}</svg>`;

  const documentList = ({ value: { documents, layout = '' } }) => {
    if (!documents) return;

    return (
      documents &&
      `<ul class="document-list${
        layout ? ` document-list--${layout}` : ``
      }">${documents
        .map((document) => {
          const { asset } = getFile(document, client.config());
          const { extension } = asset;

          return `<li>
          <a href="${asset.url}">
            ${Icon(
              extension.match(/(doc|ppt)x?|pdf/)
                ? extension.replace('x', '')
                : 'link'
            )}

            ${document.displayName || document.asset._ref}
          </a>
        </li>`;
        })
        .join('')}
    </ul>`
    );
  };

  const event = ({ children, value: { title, subtitle, ...event } }) => {
    let url = eventUrl(event);

    return `<a href="${url}">${
      children || [title, subtitle].filter(Boolean).join(': ') || url
    }</a>`;
  };

  const page = ({ children, value: { title, hash } }) => {
    let url = data.links[id];

    if (hash) url += `#${hash}`;

    return `<a href="${url}">${children || title || url}</a>`;
  };

  const contactLink = ({ children, value }) => {
    let { type, reference, subject } = value;
    let contact = reference[type];

    if (!contact) return children || contact;

    return `<a href="${type == 'emailAddress' ? 'mailto:' : 'tel:'}${contact
      .replace(/\s/g, '')
      .replace('(0)', '')}${
      subject && type == 'emailAddress'
        ? `?subject=${encodeURIComponent(subject)}`
        : ``
    }">${children || contact}</a>`;
  };

  const contactInformation = ({ value, isInline }) => {
    let { emailAddress, phone, title, firstName, lastName, role } = value;

    if (isInline) return value.join(' ');

    const isPerson = firstName || lastName || role;

    if (isPerson) title = [firstName, lastName].join(' ') || role;

    return `<section class="contact-information">
      ${title ? `<h1>${title}</h1>` : ``}
      ${isPerson && title != role ? `<h2>${role}</h2>` : ``}
  
      <ul>${[
        phone &&
          `<a href="tel:${escapeHTML(
            phone.replace(/\s/g, '').replace('(0)', '')
          )}">
            ${Icon('phone')} ${escapeHTML(phone)}
          </a>`,

        emailAddress &&
          `<a href="mailto:${escapeHTML(emailAddress)}">
            ${Icon('envelope')} ${escapeHTML(emailAddress)}
          </a>`
      ]
        .filter((x) => x)
        .map((x) => `<li>${x}</li>`)
        .join('')}</ul>
    </section>`;
  };

  const sponsorLogos = ({ value: { sponsors } }) => {
    sponsors.map((sponsor) =>
      Object.assign(sponsor, {
        logo:
          sponsor.logos &&
          sponsor.logos[0] &&
          imageUrlBuilder.image(sponsor.logos[0]).url()
      })
    );

    return `<ul class="logo-list" x-data="proportionalImageGroup">${sponsors
      .filter(({ logo }) => logo)
      .map(
        ({ logo, name, website, scaleMultiplier }) => `<li${
          scaleMultiplier ? ` style="font-size:${scaleMultiplier}em"` : ``
        }>
        ${website ? `<a href="${website}" target="_blank" class="logo">` : ``}
          <img src="${logo}" title="${name}" crossorigin="anonymous">
        ${website ? `</a>` : ``}
      </li>`
      )
      .join(' ')}</ul>`;
  };

  return toHTML(blocks, {
    components: {
      types: {
        contactInformation,
        contactLink,
        documentList,
        sponsorLogos,
        html: ({ value: { contents } }) => contents,
        path: ({ value: { path } }) => `<path d="${escapeHTML(path)}"/>`,
        event,
        page: internalLink
      },
      marks: {
        contactLink,
        internalLink,
        link: ({ children, value }) => {
          const { target, href = '' } = value;

          const looksSafe = uriLooksSafe(href);

          return looksSafe
            ? `<a href="${escapeHTML(href)}"${
                target ? ` target="${target}"` : ``
              }>${children}</a>`
            : children;
        }
      },
      block: {
        normal: ({ children }) =>
          children ? `<p prevent-widows>${children}</p>` : ``,
        small: ({ children }) =>
          children ? `<p prevent-widows><small>${children}</small></p>` : ``,
        columns: ({ children }) => `<div class="columns">${children}</div>`,
        lang: ({ children, value: { lang } }) =>
          `<p lang="${lang}" dir="auto">${children}</p>`,
        svg
      }
    },
    onMissingComponent: false
  });
};
