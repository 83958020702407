const { toHTML } = require('../../../src/utils/sanity/index.js');
const Icon = require('../../utils/icon.js');

module.exports = function ({ title, icon, rows, blockHelpers, ...data }) {
  let html = `<section class="block-table" ${blockHelpers.id(data)}>
    <h1 class="heading-lg">${
      (icon?.url && Icon(icon._id, undefined, undefined, icon.url)) ||
      (icon?._type && toHTML([icon], data)) ||
      ``
    }${title}</h1>

    ${
      rows
        ? rows
            .flatMap(
              ({ columns, ...row }) =>
                `<div ${blockHelpers.id({ ...data, ...row })}>${columns
                  .flatMap(
                    ({ title, text }) =>
                      `<div>
                        ${title ? `<h2>${title}</h2>` : ``}
                        ${text ? toHTML(text, data) : ``}
                      </div>`
                  )
                  .join('')}</div>`
            )
            .join('')
        : ``
    }
  </section>`;

  if (title == 'Conditions of Entry') {
    html = html.replaceAll(/<ul>(<li>\w+<\/li>)+<\/ul>/g, (match) => {
      return match
        .replaceAll(
          />((backpack|suitcase|umbrella)\w*)</gi,
          (match, word, icon) =>
            match.replace(
              word,
              `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" style="display:block;margin:auto;width:100%;max-width:4.25em">
                <symbol id="${icon}" viewBox="0 0 100 100">
                  <use href="/assets/svgs/${icon.toLowerCase()}.svg#path"/>
                </symbol>
                <use href="#${icon}" width="60" height="60" x="20" y="20"/>
                <use href="/assets/svgs/prohibited.svg#path"/>
              </svg>
              ${word}`
            )
        )
        .replace(
          /<ul/,
          `$& style="${[
            'align-items: stretch',
            'align-content: space-between',
            'display: inline-flex',
            'flex-wrap: wrap',
            'gap: 1.5em',
            'justify-content: start',
            'list-style-type: none',
            'padding: 0'
          ].join(';')}"`
        )
        .replace(
          /<li/g,
          `$& style="${[
            'display: grid',
            'gap: 0.5em',
            'justify-content: center'
          ].join(';')}"`
        );
    });
  }

  return html;
};
